import React, { useState, useEffect, useCallback, useRef, useContext } from "react"
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Typography,
    Divider,
    TextField,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Backdrop,
    CircularProgress
} from '@mui/material'
import { Button } from '@material-ui/core'
import { API, graphqlOperation } from 'aws-amplify';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api'
import TitleHeader from "../components/TitleHeader"
import UserContext from "../context/UserContext"
import * as queries from '../graphql/queries'


const baseMarkerURL = process.env.REACT_APP_MAP_MARKER_URL

async function fetchAppConfigData() {
    const response = await fetch(`${baseMarkerURL}mapConfig.json`);
    const data = await response.json();
    return data;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '90%',
        margin: '0 auto'
    },
    title: {
        marginBlock: '2%',
        marginBottom: '5%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    } 
}))

/**
 * 端末地図ページ
 * @param {history} props 
 * @returns 
 */
const TerminalMapPage = (props) => {
    // ユーザグループ取得
    const ADMIN_USER_GROUP_NAME = process.env.REACT_APP_ADMIN_GID
    const userGroups = useContext(UserContext).getCurrentUserGroup()
    const isAdmin = userGroups.includes(ADMIN_USER_GROUP_NAME)

    useEffect(() => {
        // 端末最新情報取得
        const getManagementTerminalList = async () => {
            setOpen(true)
            try {
                const response = await API.graphql(graphqlOperation(queries.listTerminalMapScreenDataByGroupId, { GroupId: userGroups[0] }))
                setTerminalList(response.data.listRegisteredTerminals.items)
                setFilteredTerminalList(response.data.listRegisteredTerminals.items)
            } catch (e) {
                console.log(e)
            }
            setOpen(false)
        }
        // 管理者端末最新情報取得
        const getManagementTerminalListAdmin = async () => {
            const latestData = await allTerminalRequest()
            setTerminalList(latestData)
            setFilteredTerminalList(latestData)
        }
        

        fetchAppConfigData().then((configData) => {
            // Do something with the configuration data
            console.log("AppConfig data:", configData);
            setMapMarkerConfig(configData);
        }).catch((error) => {
            console.error("Error fetching AppConfig data:", error);
        }).finally(() => {
            if (isAdmin) {
                // 全ての端末情報を取得する
                getManagementTerminalListAdmin()
            } else {
                // GroupIdの当てはまる端末情報を取得する。
                getManagementTerminalList()
            }
        });
    }, [])

    const allTerminalRequest = async() => {
        setOpen(true)
        let nextToken = null
        const retTerminalList = []
        do {
            try {
                const response = await API.graphql(graphqlOperation(queries.listTerminalMapScreenData, {nextToken: nextToken, limit: 1000, filter: {Model: {attributeType: "string"}}}))
                const termnalLatestData = response.data.listRegisteredTerminals.items
                nextToken = response.data.listRegisteredTerminals.nextToken
                retTerminalList.push(...termnalLatestData)
            } catch (e) {
                console.log(e)
                nextToken = null
            }
        } while (nextToken)
        setOpen(false)
        return retTerminalList
    }

    const classes = useStyles()
    const { history } = props
    const resultType = {
        OK: "OK",
        Waiting: "Waiting",
        NG: "NG",
        None: "None",
        All: "All"
    }
    const actionType = {
        All: "All",
        True: "true",
        False: "false"
    }

    const [mapMarkerConfig, setMapMarkerConfig] = useState(null)
    const [terminalList, setTerminalList] = useState([])
    const [filteredTerminalList, setFilteredTerminalList] = useState([])
    const [sn, setSn] = useState("")
    const [action, setAction] = useState(actionType.All)
    const [firmwareVersion, setFirmwareVersion] = useState("")
    const [result, setResult] = useState(resultType.All)
    const [mouseOver, setMouseOver] = useState(null)
    const [size, setSize] = useState(undefined)
    const [open, setOpen] = useState(false)

    
    function getMarkerIcon(terminal) {
        const baseURL = baseMarkerURL
        let path = "base_icon_active.png"

        if (mapMarkerConfig == null) {
            return {
                url: baseURL + path,
                scaledSize: new window.google.maps.Size(30, 35),
            }
        }

        
        let iconData = null
        const icons = mapMarkerConfig.icons
        for (let i = 0; i < icons.length; i++) {
            const iconConfig = icons[i]
            for (let j = 0; j < iconConfig.requirements.length; j++) {
                const requirement = iconConfig.requirements[j]
                if (terminal[requirement.field] && terminal[requirement.field].startsWith(requirement.beginWith)) {
                    iconData = iconConfig
                    break
                }
            }
        }

        const outOfRangeMaxHours = mapMarkerConfig.outOfRangeMaxHours
        let isOutOfRange = false
        let isIssue = false
        if (!terminal.Time || new Date().getTime() - new Date(terminal.Time).getTime() > outOfRangeMaxHours * 60 * 60 * 1000) {
            isOutOfRange = true
        }
        // loop thought mapMarkerConfig.issueConditions
        for (let i = 0; i < mapMarkerConfig.issueConditions.length; i++) {
            const issueCondition = mapMarkerConfig.issueConditions[i]
            let isThisConditionValid = true
            for (let j = 0; j < issueCondition.conditions.length; j++) {
                const condition = issueCondition.conditions[j]
                if (!terminal[condition.field]) {
                    isThisConditionValid = false
                    break
                }
                if (terminal[condition.field] && condition.operator === "==" && terminal[condition.field] !== condition.value) {
                    isThisConditionValid = false
                    break
                }
                if (terminal[condition.field] && condition.operator === "!=" && terminal[condition.field] === condition.value) {
                    isThisConditionValid = false
                    break
                }
                if (terminal[condition.field] && condition.operator === "<" && terminal[condition.field] >= condition.value) {
                    isThisConditionValid = false
                    break
                }
                if (terminal[condition.field] && condition.operator === ">" && terminal[condition.field] <= condition.value) {
                    isThisConditionValid = false
                    break
                }
                if (terminal[condition.field] && condition.operator === "<=" && terminal[condition.field] > condition.value) {
                    isThisConditionValid = false
                    break
                }
                if (terminal[condition.field] && condition.operator === ">=" && terminal[condition.field] < condition.value) {
                    isThisConditionValid = false
                    break
                }
            }
            if (isThisConditionValid) {
                isIssue = true
                break
            }
        }
        let prefix = "active"
        if (isOutOfRange) {
            prefix = "out_of_range"
        }
        if (isIssue) {
            prefix = "issue"
        }
        if (iconData != null) {  
            path = `base_icon_${prefix}_${iconData.name}.png`
        } else {
            path = `base_icon_${prefix}.png`
        }

        return {
            url: baseURL + path,
            scaledSize: new window.google.maps.Size(30, 35),
        }
    }

    const infoWindowOptions = {
        pixelOffset: size,
    }

    // 地図の表示位置中心
    const center = {
        lat: 35.652832,
        lng: 139.839478,
    }

    // 地図表示スタイル
    const containerStyle = {
        width: "100%",
        height: "100vh",
    }

    const createOffsetSize = () => {
        return setSize(new window.google.maps.Size(0, -45));
    }
    
    const onClickBackBtn = () => {
        history.goBack()
    }

    const onClickMarker = (mac) => {
        history.push({pathname: '/setting', mac: mac})
    }

    const handleChangeSn = (event) => {
        const sn = event.target.value
        setSn(sn)
    }

    // const handleChangeAction = (event) => {
    //     const action = event.target.value
    //     setAction(action)
    // }

    const handleChangeAction = (event) => {
        const action = event.target.value
        setAction(action)
    }

    const handleChangeFirmwareVersion = (event) => {
        const firmware = event.target.value
        setFirmwareVersion(firmware)
    }

    const handleSelectChange = (event) => {
        setResult(event.target.value)
    }

    const handleOnClickFilter = () => {
        const filteringTerminalList = terminalList.filter(item => {
            return !item.SN.indexOf(sn)
            // return item.SN == "000001"
            // return item.MAC == "22:33:44:55:66:77"
        })
        .filter(item => {
            return !item.Firmware.indexOf(firmwareVersion)
        })
        .filter(item => {
            if(action !== actionType.All) {
                if(item.Action != null) {
                    return item.Action === action
                } else {
                    // データがnullの場合
                    if(action === actionType.False) {
                        // デフォルト値はfalseの為false条件設定で表示する
                        return true
                    } else {
                        return false
                    }
                }
            }
            return true
        })
        .filter(item => {
            if(result === resultType.All) {
                return true
            } else if(result === resultType.None) {
                if(item.Result == null) {
                    return true
                }
                return item.Result === result
            } else {
                return item.Result === result
            }
            // switch(result){
            //     case resultType.All : 
            //         return true
            //     default : 
            //         break
            // }

            // if(item.Result != null) {
            //     if(result === resultType.All) {
            //         return true
            //     }
            //     return item.Result === result
            // }
            // return true
        })

        setFilteredTerminalList(filteringTerminalList) 
    }

    const handleOnLoad = (map) => {
        createOffsetSize()
        map.setCenter(center)
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <TitleHeader history={props.history}/>
                <Box display='flex' justifyContent='left' alignItems='center'>
                    <Typography variant='h4' align='left' style={{ margin: '10px', width: 'auto' }}>Filter:</Typography>
                    <TextField
                        margin="normal"
                        id="sn"
                        label="Serial Number"
                        type="text"
                        variant="outlined"
                        value={sn}
                        style={{ marginLeft: '10px' }}
                        onChange={handleChangeSn}
                    />
                    <Box style={{ marginLeft: '10px' }}>
                        <FormControl margin="normal" style={{ minWidth: 120 }}>
                            <InputLabel>Action</InputLabel>
                            <Select
                                value={action}
                                label='Action'
                                onChange={handleChangeAction} >
                                <MenuItem value={actionType.All}>All</MenuItem>
                                <MenuItem value={actionType.True}>True</MenuItem>
                                <MenuItem value={actionType.False}>False</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <TextField
                        margin="normal"
                        id="firmwareVersion"
                        label="Firmware Version"
                        type="text"
                        variant="outlined"
                        value={firmwareVersion}
                        style={{ marginLeft: '10px' }}
                        onChange={handleChangeFirmwareVersion}
                    />
                    <Box style={{ marginLeft: '10px' }}>
                        <FormControl margin="normal" style={{ minWidth: 120 }}>
                            <InputLabel>Result</InputLabel>
                            <Select
                                value={result}
                                label='Result'
                                onChange={handleSelectChange} >
                                <MenuItem value={resultType.All}>All</MenuItem>
                                <MenuItem value={resultType.OK}>OK</MenuItem>
                                <MenuItem value={resultType.Waiting}>Waiting</MenuItem>
                                <MenuItem value={resultType.NG}>NG</MenuItem>
                                <MenuItem value={resultType.None}>NONE</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Button onClick={handleOnClickFilter} variant="contained" size="large" style={{ marginLeft: "30px"}}>Filter</Button>
                </Box>
                <Box sx={
                    { justifyContent: 'flex-start', alignItems: 'flex-start' }
                } style={{ display: 'flex', marginBlock: '10px' }}>
                    <Divider />
                    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            onLoad={handleOnLoad}
                            zoom={10}
                        >
                            <>
                                {filteredTerminalList.map((filteredTerminal) =>{
                                    // if(filteredTerminal.Action) {
                                    //     console.log(filteredTerminal.Action)
                                    // } else if(filteredTerminal.Action == null) {
                                    //     console.log(filteredTerminal.Action)
                                    // }
                                    
                                    return (<Marker
                                        position={{lat: filteredTerminal.Lat, lng: filteredTerminal.Lon}}
                                        onClick={() => onClickMarker(filteredTerminal.MAC)}
                                        data-mac={filteredTerminal.MAC}
                                        key={filteredTerminal.MAC}
                                        onMouseOver={() => {
                                            setMouseOver(filteredTerminal)
                                        }}
                                        icon={getMarkerIcon(filteredTerminal)}
                                    />)
                                }
                                )}
                                {mouseOver && (
                                    <InfoWindow
                                        position={{
                                            lat: mouseOver.Lat,
                                            lng: mouseOver.Lon,
                                        }}
                                        options={infoWindowOptions}
                                        onCloseClick={() => {
                                            setMouseOver(null);
                                        }}
                                    >
                                        <div>{mouseOver.SN}</div>
                                    </InfoWindow>
                                )}
                            </>
                        </GoogleMap>
                    </LoadScript>
                </Box>
            </Box>
            <Button variant="contained" style={{ marginTop: '30px', marginBottom: '10px' }} onClick={onClickBackBtn}>Back</Button>
            <Backdrop open={open} className={classes.backdrop} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default TerminalMapPage