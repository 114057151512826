import React from 'react';
import {
    withRouter,
    Switch,
    Route,
    Redirect,
    BrowserRouter as Router
  } from 'react-router-dom';
import UserContext from './context/UserContext';
import Authenticator from './components/Authenticator';
import OTAServerManagementPage from './page/OTAServerManagementPage';
import RouterSettingPage from './page/RouterSettingPage';
import TerminalMapPage from './page/TerminalMapPage'
import TerminalRegistration from './page/TerminalRegistration';

class PrivateRoute extends React.Component {
    state = {
        loaded: false,
        isAuthenticated: false
    }
    static contextType = UserContext
    componentDidMount() {
        this.unlisten = this.props.history.listen(() => {
            this.context.updateCurrentUser()
        })
    }
    componentWillUnmount() {
        this.unlisten()
    }
    render() {
        const { component: Component, ...rest } = this.props
        const isAuthenticated = this.context.user && this.context.user.username ? true : false
        const isLoaded = this.context.isLoaded
        if (!isLoaded) {
            return null
        }
        return (
            <Route
                {...rest}
                render={props => {
                return isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                    to={{
                        pathname: "/",
                    }}
                    />
                )
                }}
            />
        )
    }
}

class GuestRoute extends React.Component{
    state = {
        loaded: false,
        isAuthenticated: false
    }
    static contextType = UserContext
    componentDidMount() {
        this.unlisten = this.props.history.listen(() => {
            this.context.updateCurrentUser()
        })
    }
    componentWillUnmount() {
        this.unlisten()
    }
    render() {
        const { component: Component, ...rest } = this.props
        const isAuthenticated = this.context.user && this.context.user.username ? true : false
        const isLoaded = this.context.isLoaded
        if (!isLoaded) {
            return null
        }
        return (
        <Route
            {...rest}
            render={props => {
                return isAuthenticated ? (
                    <Redirect
                    to={{
                        pathname: "/manage",
                    }}
                    />
                ) : (
                    <Component {...props} />
                )
            }}
        />
        )
    }
}

const NoMatch = () => (
    <div>
        <h1>this path is no location.</h1>
    </div>
//  他のパスを直接入力した際にSignInページにリダイレクト 
//   <Redirect
//     to={{
//       pathname: "/",
//     }}
//   />
)

PrivateRoute = withRouter(PrivateRoute)

GuestRoute = withRouter(GuestRoute)

const Routes = () => (
    <Router>
        {/* <div> */}
        <Switch>
            <GuestRoute path='/' exact component={Authenticator} />
            <PrivateRoute path='/manage' exact component={OTAServerManagementPage} />
            <PrivateRoute path='/setting' exact component={RouterSettingPage} />
            <PrivateRoute path='/map' exact component={TerminalMapPage} />
            <PrivateRoute path='/registration' exact component={TerminalRegistration} />
            <Route component={NoMatch} />
        </Switch>
        {/* </div> */}
    </Router>
)

export default Routes
