/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRegisteredTerminal = /* GraphQL */ `
  mutation CreateRegisteredTerminal(
    $input: CreateRegisteredTerminalInput!
    $condition: ModelRegisteredTerminalConditionInput
  ) {
    createRegisteredTerminal(input: $input, condition: $condition) {
      GroupId
      MAC
      Model
      SN
      Firmware
      Uptime
      WanIp
      CellularModule
      IMEI
      MSISDN
      NetType
      RSSI
      LTERSRP
      NRRSRP
      MCC
      MNC
      Result
      Action
      Time
      LastUpdated
      Address
      Lat
      Lon
      ScriptPath
      Config
      createdAt
      updatedAt
      TerminalInfo {
        items {
          MAC
          Order
          SerialNumber
          Model
          FirmwareVersion
          Uptime
          WAN_IP
          Module
          IMEI
          MSISDN
          Config
          NetworkType
          RSSI_Min
          RSSI_Avg
          RSSI_Max
          LTE_RSRP_Min
          LTE_RSRP_Avg
          LTE_RSRP_Max
          NR_RSR_Min
          NR_RSR_Avg
          NR_RSR_Max
          MCC
          MNC
          TAC_LAC
          CellID
          Channel
          ra0_rx_bytes_Min
          ra0_rx_bytes_Avg
          ra0_rx_bytes_Max
          ra0_tx_bytes_Min
          ra0_tx_bytes_Avg
          ra0_tx_bytes_Max
          ra0_clients_Min
          ra0_clients_Avg
          ra0_clients_Max
          ra1_rx_bytes_Min
          ra1_rx_bytes_Avg
          ra1_rx_bytes_Max
          ra1_tx_bytes_Min
          ra1_tx_bytes_Avg
          ra1_tx_bytes_Max
          ra1_clients_Min
          ra1_clients_Avg
          ra1_clients_Max
          ra2_rx_bytes_Min
          ra2_rx_bytes_Avg
          ra2_rx_bytes_Max
          ra2_tx_bytes_Min
          ra2_tx_bytes_Avg
          ra2_tx_bytes_Max
          ra2_clients_Min
          ra2_clients_Avg
          ra2_clients_Max
          ra3_rx_bytes_Min
          ra3_rx_bytes_Avg
          ra3_rx_bytes_Max
          ra3_tx_bytes_Min
          ra3_tx_bytes_Avg
          ra3_tx_bytes_Max
          ra3_clients_Min
          ra3_clients_Avg
          ra3_clients_Max
          ra4_rx_bytes_Min
          ra4_rx_bytes_Avg
          ra4_rx_bytes_Max
          ra4_tx_bytes_Min
          ra4_tx_bytes_Avg
          ra4_tx_bytes_Max
          ra4_clients_Min
          ra4_clients_Avg
          ra4_clients_Max
          ra5_rx_bytes_Min
          ra5_rx_bytes_Avg
          ra5_rx_bytes_Max
          ra5_tx_bytes_Min
          ra5_tx_bytes_Avg
          ra5_tx_bytes_Max
          ra5_clients_Min
          ra5_clients_Avg
          ra5_clients_Max
          ra6_rx_bytes_Min
          ra6_rx_bytes_Avg
          ra6_rx_bytes_Max
          ra6_tx_bytes_Min
          ra6_tx_bytes_Avg
          ra6_tx_bytes_Max
          ra6_clients_Min
          ra6_clients_Avg
          ra6_clients_Max
          ra7_rx_bytes_Min
          ra7_rx_bytes_Avg
          ra7_rx_bytes_Max
          ra7_tx_bytes_Min
          ra7_tx_bytes_Avg
          ra7_tx_bytes_Max
          ra7_clients_Min
          ra7_clients_Avg
          ra7_clients_Max
          rx_bytes_Min
          rx_bytes_AVG
          rx_bytes_Max
          tx_bytes_Min
          tx_bytes_AVG
          tx_bytes_Max
          Time
          Script
          Action
          Result
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteRegisteredTerminal = /* GraphQL */ `
  mutation DeleteRegisteredTerminal(
    $input: DeleteRegisteredTerminalInput!
    $condition: ModelRegisteredTerminalConditionInput
  ) {
    deleteRegisteredTerminal(input: $input, condition: $condition) {
      GroupId
      MAC
      Model
      SN
      Firmware
      Uptime
      WanIp
      CellularModule
      IMEI
      MSISDN
      NetType
      RSSI
      LTERSRP
      NRRSRP
      MCC
      MNC
      Result
      Action
      Time
      LastUpdated
      Address
      Lat
      Lon
      ScriptPath
      Config
      TerminalInfo {
        items {
          MAC
          Order
          SerialNumber
          Model
          FirmwareVersion
          Uptime
          WAN_IP
          Module
          IMEI
          MSISDN
          Config
          NetworkType
          RSSI_Min
          RSSI_Avg
          RSSI_Max
          LTE_RSRP_Min
          LTE_RSRP_Avg
          LTE_RSRP_Max
          NR_RSR_Min
          NR_RSR_Avg
          NR_RSR_Max
          MCC
          MNC
          TAC_LAC
          CellID
          Channel
          ra0_rx_bytes_Min
          ra0_rx_bytes_Avg
          ra0_rx_bytes_Max
          ra0_tx_bytes_Min
          ra0_tx_bytes_Avg
          ra0_tx_bytes_Max
          ra0_clients_Min
          ra0_clients_Avg
          ra0_clients_Max
          ra1_rx_bytes_Min
          ra1_rx_bytes_Avg
          ra1_rx_bytes_Max
          ra1_tx_bytes_Min
          ra1_tx_bytes_Avg
          ra1_tx_bytes_Max
          ra1_clients_Min
          ra1_clients_Avg
          ra1_clients_Max
          ra2_rx_bytes_Min
          ra2_rx_bytes_Avg
          ra2_rx_bytes_Max
          ra2_tx_bytes_Min
          ra2_tx_bytes_Avg
          ra2_tx_bytes_Max
          ra2_clients_Min
          ra2_clients_Avg
          ra2_clients_Max
          ra3_rx_bytes_Min
          ra3_rx_bytes_Avg
          ra3_rx_bytes_Max
          ra3_tx_bytes_Min
          ra3_tx_bytes_Avg
          ra3_tx_bytes_Max
          ra3_clients_Min
          ra3_clients_Avg
          ra3_clients_Max
          ra4_rx_bytes_Min
          ra4_rx_bytes_Avg
          ra4_rx_bytes_Max
          ra4_tx_bytes_Min
          ra4_tx_bytes_Avg
          ra4_tx_bytes_Max
          ra4_clients_Min
          ra4_clients_Avg
          ra4_clients_Max
          ra5_rx_bytes_Min
          ra5_rx_bytes_Avg
          ra5_rx_bytes_Max
          ra5_tx_bytes_Min
          ra5_tx_bytes_Avg
          ra5_tx_bytes_Max
          ra5_clients_Min
          ra5_clients_Avg
          ra5_clients_Max
          ra6_rx_bytes_Min
          ra6_rx_bytes_Avg
          ra6_rx_bytes_Max
          ra6_tx_bytes_Min
          ra6_tx_bytes_Avg
          ra6_tx_bytes_Max
          ra6_clients_Min
          ra6_clients_Avg
          ra6_clients_Max
          ra7_rx_bytes_Min
          ra7_rx_bytes_Avg
          ra7_rx_bytes_Max
          ra7_tx_bytes_Min
          ra7_tx_bytes_Avg
          ra7_tx_bytes_Max
          ra7_clients_Min
          ra7_clients_Avg
          ra7_clients_Max
          rx_bytes_Min
          rx_bytes_AVG
          rx_bytes_Max
          tx_bytes_Min
          tx_bytes_AVG
          tx_bytes_Max
          Time
          Script
          Action
          Result
        }
        nextToken
      }
    }
  }
`;
export const updateTerminalInfo = /* GraphQL */ `
  mutation UpdateTerminalInfo(
    $input: UpdateTerminalInfoInput!
    $condition: ModelTerminalInfoConditionInput
  ) {
    updateTerminalInfo(input: $input, condition: $condition) {
      MAC
      Order
      SerialNumber
      Model
      FirmwareVersion
      Uptime
      WAN_IP
      Module
      IMEI
      MSISDN
      Config
      NetworkType
      RSSI_Min
      RSSI_Avg
      RSSI_Max
      LTE_RSRP_Min
      LTE_RSRP_Avg
      LTE_RSRP_Max
      NR_RSR_Min
      NR_RSR_Avg
      NR_RSR_Max
      MCC
      MNC
      TAC_LAC
      CellID
      Channel
      ra0_rx_bytes_Min
      ra0_rx_bytes_Avg
      ra0_rx_bytes_Max
      ra0_tx_bytes_Min
      ra0_tx_bytes_Avg
      ra0_tx_bytes_Max
      ra0_clients_Min
      ra0_clients_Avg
      ra0_clients_Max
      ra1_rx_bytes_Min
      ra1_rx_bytes_Avg
      ra1_rx_bytes_Max
      ra1_tx_bytes_Min
      ra1_tx_bytes_Avg
      ra1_tx_bytes_Max
      ra1_clients_Min
      ra1_clients_Avg
      ra1_clients_Max
      ra2_rx_bytes_Min
      ra2_rx_bytes_Avg
      ra2_rx_bytes_Max
      ra2_tx_bytes_Min
      ra2_tx_bytes_Avg
      ra2_tx_bytes_Max
      ra2_clients_Min
      ra2_clients_Avg
      ra2_clients_Max
      ra3_rx_bytes_Min
      ra3_rx_bytes_Avg
      ra3_rx_bytes_Max
      ra3_tx_bytes_Min
      ra3_tx_bytes_Avg
      ra3_tx_bytes_Max
      ra3_clients_Min
      ra3_clients_Avg
      ra3_clients_Max
      ra4_rx_bytes_Min
      ra4_rx_bytes_Avg
      ra4_rx_bytes_Max
      ra4_tx_bytes_Min
      ra4_tx_bytes_Avg
      ra4_tx_bytes_Max
      ra4_clients_Min
      ra4_clients_Avg
      ra4_clients_Max
      ra5_rx_bytes_Min
      ra5_rx_bytes_Avg
      ra5_rx_bytes_Max
      ra5_tx_bytes_Min
      ra5_tx_bytes_Avg
      ra5_tx_bytes_Max
      ra5_clients_Min
      ra5_clients_Avg
      ra5_clients_Max
      ra6_rx_bytes_Min
      ra6_rx_bytes_Avg
      ra6_rx_bytes_Max
      ra6_tx_bytes_Min
      ra6_tx_bytes_Avg
      ra6_tx_bytes_Max
      ra6_clients_Min
      ra6_clients_Avg
      ra6_clients_Max
      ra7_rx_bytes_Min
      ra7_rx_bytes_Avg
      ra7_rx_bytes_Max
      ra7_tx_bytes_Min
      ra7_tx_bytes_Avg
      ra7_tx_bytes_Max
      ra7_clients_Min
      ra7_clients_Avg
      ra7_clients_Max
      rx_bytes_Min
      rx_bytes_AVG
      rx_bytes_Max
      tx_bytes_Min
      tx_bytes_AVG
      tx_bytes_Max
      Time
      Script
      Action
      Result
      createdAt
      updatedAt
    }
  }
`;
export const deleteTerminalInfo = /* GraphQL */ `
  mutation DeleteTerminalInfo(
    $input: DeleteTerminalInfoInput!
    $condition: ModelTerminalInfoConditionInput
  ) {
    deleteTerminalInfo(input: $input, condition: $condition) {
      MAC
      Order
      SerialNumber
      Model
      FirmwareVersion
      Uptime
      WAN_IP
      Module
      IMEI
      MSISDN
      Config
      NetworkType
      RSSI_Min
      RSSI_Avg
      RSSI_Max
      LTE_RSRP_Min
      LTE_RSRP_Avg
      LTE_RSRP_Max
      NR_RSR_Min
      NR_RSR_Avg
      NR_RSR_Max
      MCC
      MNC
      TAC_LAC
      CellID
      Channel
      ra0_rx_bytes_Min
      ra0_rx_bytes_Avg
      ra0_rx_bytes_Max
      ra0_tx_bytes_Min
      ra0_tx_bytes_Avg
      ra0_tx_bytes_Max
      ra0_clients_Min
      ra0_clients_Avg
      ra0_clients_Max
      ra1_rx_bytes_Min
      ra1_rx_bytes_Avg
      ra1_rx_bytes_Max
      ra1_tx_bytes_Min
      ra1_tx_bytes_Avg
      ra1_tx_bytes_Max
      ra1_clients_Min
      ra1_clients_Avg
      ra1_clients_Max
      ra2_rx_bytes_Min
      ra2_rx_bytes_Avg
      ra2_rx_bytes_Max
      ra2_tx_bytes_Min
      ra2_tx_bytes_Avg
      ra2_tx_bytes_Max
      ra2_clients_Min
      ra2_clients_Avg
      ra2_clients_Max
      ra3_rx_bytes_Min
      ra3_rx_bytes_Avg
      ra3_rx_bytes_Max
      ra3_tx_bytes_Min
      ra3_tx_bytes_Avg
      ra3_tx_bytes_Max
      ra3_clients_Min
      ra3_clients_Avg
      ra3_clients_Max
      ra4_rx_bytes_Min
      ra4_rx_bytes_Avg
      ra4_rx_bytes_Max
      ra4_tx_bytes_Min
      ra4_tx_bytes_Avg
      ra4_tx_bytes_Max
      ra4_clients_Min
      ra4_clients_Avg
      ra4_clients_Max
      ra5_rx_bytes_Min
      ra5_rx_bytes_Avg
      ra5_rx_bytes_Max
      ra5_tx_bytes_Min
      ra5_tx_bytes_Avg
      ra5_tx_bytes_Max
      ra5_clients_Min
      ra5_clients_Avg
      ra5_clients_Max
      ra6_rx_bytes_Min
      ra6_rx_bytes_Avg
      ra6_rx_bytes_Max
      ra6_tx_bytes_Min
      ra6_tx_bytes_Avg
      ra6_tx_bytes_Max
      ra6_clients_Min
      ra6_clients_Avg
      ra6_clients_Max
      ra7_rx_bytes_Min
      ra7_rx_bytes_Avg
      ra7_rx_bytes_Max
      ra7_tx_bytes_Min
      ra7_tx_bytes_Avg
      ra7_tx_bytes_Max
      ra7_clients_Min
      ra7_clients_Avg
      ra7_clients_Max
      rx_bytes_Min
      rx_bytes_AVG
      rx_bytes_Max
      tx_bytes_Min
      tx_bytes_AVG
      tx_bytes_Max
      Time
      Script
      Action
      Result
      createdAt
      updatedAt
    }
  }
`;
export const updateRegisteredTerminal = /* GraphQL */ `
  mutation UpdateRegisteredTerminal(
    $input: UpdateRegisteredTerminalInput!
    $condition: ModelRegisteredTerminalConditionInput
  ) {
    updateRegisteredTerminal(input: $input, condition: $condition) {
      GroupId
      MAC
      Model
      SN
      Firmware
      Uptime
      WanIp
      CellularModule
      IMEI
      MSISDN
      NetType
      RSSI
      LTERSRP
      NRRSRP
      MCC
      MNC
      Result
      Action
      Time
      LastUpdated
      Address
      Lat
      Lon
      ScriptPath
      Config
      createdAt
      updatedAt
      TerminalInfo {
        items {
          MAC
          Order
          SerialNumber
          Model
          FirmwareVersion
          Uptime
          WAN_IP
          Module
          IMEI
          MSISDN
          Config
          NetworkType
          RSSI_Min
          RSSI_Avg
          RSSI_Max
          LTE_RSRP_Min
          LTE_RSRP_Avg
          LTE_RSRP_Max
          NR_RSR_Min
          NR_RSR_Avg
          NR_RSR_Max
          MCC
          MNC
          TAC_LAC
          CellID
          Channel
          ra0_rx_bytes_Min
          ra0_rx_bytes_Avg
          ra0_rx_bytes_Max
          ra0_tx_bytes_Min
          ra0_tx_bytes_Avg
          ra0_tx_bytes_Max
          ra0_clients_Min
          ra0_clients_Avg
          ra0_clients_Max
          ra1_rx_bytes_Min
          ra1_rx_bytes_Avg
          ra1_rx_bytes_Max
          ra1_tx_bytes_Min
          ra1_tx_bytes_Avg
          ra1_tx_bytes_Max
          ra1_clients_Min
          ra1_clients_Avg
          ra1_clients_Max
          ra2_rx_bytes_Min
          ra2_rx_bytes_Avg
          ra2_rx_bytes_Max
          ra2_tx_bytes_Min
          ra2_tx_bytes_Avg
          ra2_tx_bytes_Max
          ra2_clients_Min
          ra2_clients_Avg
          ra2_clients_Max
          ra3_rx_bytes_Min
          ra3_rx_bytes_Avg
          ra3_rx_bytes_Max
          ra3_tx_bytes_Min
          ra3_tx_bytes_Avg
          ra3_tx_bytes_Max
          ra3_clients_Min
          ra3_clients_Avg
          ra3_clients_Max
          ra4_rx_bytes_Min
          ra4_rx_bytes_Avg
          ra4_rx_bytes_Max
          ra4_tx_bytes_Min
          ra4_tx_bytes_Avg
          ra4_tx_bytes_Max
          ra4_clients_Min
          ra4_clients_Avg
          ra4_clients_Max
          ra5_rx_bytes_Min
          ra5_rx_bytes_Avg
          ra5_rx_bytes_Max
          ra5_tx_bytes_Min
          ra5_tx_bytes_Avg
          ra5_tx_bytes_Max
          ra5_clients_Min
          ra5_clients_Avg
          ra5_clients_Max
          ra6_rx_bytes_Min
          ra6_rx_bytes_Avg
          ra6_rx_bytes_Max
          ra6_tx_bytes_Min
          ra6_tx_bytes_Avg
          ra6_tx_bytes_Max
          ra6_clients_Min
          ra6_clients_Avg
          ra6_clients_Max
          ra7_rx_bytes_Min
          ra7_rx_bytes_Avg
          ra7_rx_bytes_Max
          ra7_tx_bytes_Min
          ra7_tx_bytes_Avg
          ra7_tx_bytes_Max
          ra7_clients_Min
          ra7_clients_Avg
          ra7_clients_Max
          rx_bytes_Min
          rx_bytes_AVG
          rx_bytes_Max
          tx_bytes_Min
          tx_bytes_AVG
          tx_bytes_Max
          Time
          Script
          Action
          Result
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createTerminalInfo = /* GraphQL */ `
  mutation CreateTerminalInfo(
    $input: CreateTerminalInfoInput!
    $condition: ModelTerminalInfoConditionInput
  ) {
    createTerminalInfo(input: $input, condition: $condition) {
      MAC
      Order
      SerialNumber
      Model
      FirmwareVersion
      Uptime
      WAN_IP
      Module
      IMEI
      MSISDN
      Config
      NetworkType
      RSSI_Min
      RSSI_Avg
      RSSI_Max
      LTE_RSRP_Min
      LTE_RSRP_Avg
      LTE_RSRP_Max
      NR_RSR_Min
      NR_RSR_Avg
      NR_RSR_Max
      MCC
      MNC
      TAC_LAC
      CellID
      Channel
      ra0_rx_bytes_Min
      ra0_rx_bytes_Avg
      ra0_rx_bytes_Max
      ra0_tx_bytes_Min
      ra0_tx_bytes_Avg
      ra0_tx_bytes_Max
      ra0_clients_Min
      ra0_clients_Avg
      ra0_clients_Max
      ra1_rx_bytes_Min
      ra1_rx_bytes_Avg
      ra1_rx_bytes_Max
      ra1_tx_bytes_Min
      ra1_tx_bytes_Avg
      ra1_tx_bytes_Max
      ra1_clients_Min
      ra1_clients_Avg
      ra1_clients_Max
      ra2_rx_bytes_Min
      ra2_rx_bytes_Avg
      ra2_rx_bytes_Max
      ra2_tx_bytes_Min
      ra2_tx_bytes_Avg
      ra2_tx_bytes_Max
      ra2_clients_Min
      ra2_clients_Avg
      ra2_clients_Max
      ra3_rx_bytes_Min
      ra3_rx_bytes_Avg
      ra3_rx_bytes_Max
      ra3_tx_bytes_Min
      ra3_tx_bytes_Avg
      ra3_tx_bytes_Max
      ra3_clients_Min
      ra3_clients_Avg
      ra3_clients_Max
      ra4_rx_bytes_Min
      ra4_rx_bytes_Avg
      ra4_rx_bytes_Max
      ra4_tx_bytes_Min
      ra4_tx_bytes_Avg
      ra4_tx_bytes_Max
      ra4_clients_Min
      ra4_clients_Avg
      ra4_clients_Max
      ra5_rx_bytes_Min
      ra5_rx_bytes_Avg
      ra5_rx_bytes_Max
      ra5_tx_bytes_Min
      ra5_tx_bytes_Avg
      ra5_tx_bytes_Max
      ra5_clients_Min
      ra5_clients_Avg
      ra5_clients_Max
      ra6_rx_bytes_Min
      ra6_rx_bytes_Avg
      ra6_rx_bytes_Max
      ra6_tx_bytes_Min
      ra6_tx_bytes_Avg
      ra6_tx_bytes_Max
      ra6_clients_Min
      ra6_clients_Avg
      ra6_clients_Max
      ra7_rx_bytes_Min
      ra7_rx_bytes_Avg
      ra7_rx_bytes_Max
      ra7_tx_bytes_Min
      ra7_tx_bytes_Avg
      ra7_tx_bytes_Max
      ra7_clients_Min
      ra7_clients_Avg
      ra7_clients_Max
      rx_bytes_Min
      rx_bytes_AVG
      rx_bytes_Max
      tx_bytes_Min
      tx_bytes_AVG
      tx_bytes_Max
      Time
      Script
      Action
      Result
      createdAt
      updatedAt
    }
  }
`;

// ----------------------------------------------------------------
// 自作mutations
// ----------------------------------------------------------------

/**
 * 端末登録
 */
export const registTerminal = /* GraphQL */ `
  mutation MyMutation(
    $GroupId: ID!
    $MAC: ID!
    $SN: String
    $Address: String
    $Lat: Float
    $Lon: Float
  ) {
    createRegisteredTerminal(input: {MAC: $MAC, GroupId: $GroupId, SN: $SN, Address: $Address, Lat: $Lat, Lon: $Lon}) {
      MAC
    }
  }
`;

/**
 * 端末登録情報更新
 */
export const updateRegistTerminal = /* GraphQL */ `
  mutation MyMutation(
    $GroupId: ID!
    $MAC: ID!
    $SN: String
    $Address: String
    $Lat: Float
    $Lon: Float
  ) {
    updateRegisteredTerminal(input: {MAC: $MAC, GroupId: $GroupId, SN: $SN, Address: $Address, Lat: $Lat, Lon: $Lon}) {
      MAC
    }
  }
`;

/**
 * 端末Action設定
 */
 export const updateTerminalAction = /* GraphQL */ `
 mutation UpdateRegisteredTerminal(
   $input: UpdateRegisteredTerminalInput!
   $condition: ModelRegisteredTerminalConditionInput
 ) {
   updateRegisteredTerminal(input: $input, condition: $condition) {
     GroupId
     MAC
   }
 }
`;
