import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper
} from '@mui/material'
import { Button, makeStyles } from '@material-ui/core'
import { memo } from 'react'

const useStyles = makeStyles({
    cell: {
        borderRight: "0.5px solid #DEDEDE"
    },
    header: {
        borderRight: "0.5px solid #DEDEDE",
        borderTop: "0.5px solid #DEDEDE"
    }
})

const columnsCategoryDatas = [
    {
        title: 'Device',
        colSpan: 8
    },
    {
        title: 'Cellular',
        colSpan: 8
    },
    {
        title: 'Cellular - RSSI',
        colSpan: 3
    },
    {
        title: 'Cellular - LTE RSRP',
        colSpan: 3
    },
    {
        title: 'Cellular - NR RSRP',
        colSpan: 3
    },
    {
        title: 'WiFi',
        colSpan: 1
    },

    {
        title: 'WiFi - ra0 rx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra0 tx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra0 clients',
        colSpan: 3
    },
    {
        title: 'WiFi - ra1 rx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra1 tx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra1 clients',
        colSpan: 3
    },
    {
        title: 'WiFi - ra2 rx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra2 tx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra2 clients',
        colSpan: 3
    },
    {
        title: 'WiFi - ra3 rx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra3 tx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra3 clients',
        colSpan: 3
    },
    {
        title: 'WiFi - ra4 rx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra4 tx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra4 clients',
        colSpan: 3
    },
    {
        title: 'WiFi - ra5 rx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra5 tx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra5 clients',
        colSpan: 3
    },
    {
        title: 'WiFi - ra6 rx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra6 tx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra6 clients',
        colSpan: 3
    },
    {
        title: 'WiFi - ra7 rx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra7 tx bytes',
        colSpan: 3
    },
    {
        title: 'WiFi - ra7 clients',
        colSpan: 3
    },

    {
        title: 'Cellular - rx bytes',
        colSpan: 3
    },
    {
        title: 'Cellular - tx bytes',
        colSpan: 3
    },
    {
        title: 'Settings',
        colSpan: 3
    }
]

const columnsNames = [
    // Device
    "Model",
    "Serial Number",
    "MAC address",
    "Firmware version",
    "Uptime",
    "WAN IP",
    "Config",
    "Time",
    // Cellular
    "Module",
    "IMEI",
    "MSISDN",
    "Network Type",
    "MCC",
    "MNC",
    "TAC/LAC",
    "Cell ID",
    // Cellular - RSSI
    "Min",
    "Avg",
    "Max",
    // Cellular - LTE RSRP
    "Min",
    "Avg",
    "Max",
    // Cellular - NR RSRP
    "Min",
    "Avg",
    "Max",
    // WiFi
    "Channel",

    // WiFi - ra0 rx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra0 tx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra0 clients
    "Min",
    "Avg",
    "Max",
    // WiFi - ra1 rx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra1 tx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra1 clients
    "Min",
    "Avg",
    "Max",
    // WiFi - ra2 rx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra2 tx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra2 clients
    "Min",
    "Avg",
    "Max",
    // WiFi - ra3 rx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra3 tx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra3 clients
    "Min",
    "Avg",
    "Max",
    // WiFi - ra4 rx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra4 tx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra4 clients
    "Min",
    "Avg",
    "Max",
    // WiFi - ra5 rx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra5 tx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra5 clients
    "Min",
    "Avg",
    "Max",
    // WiFi - ra6 rx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra6 tx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra6 clients
    "Min",
    "Avg",
    "Max",
    // WiFi - ra7 rx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra7 tx bytes
    "Min",
    "Avg",
    "Max",
    // WiFi - ra7 clients
    "Min",
    "Avg",
    "Max",

    // Cellular - rx bytes
    "Min",
    "Avg",
    "Max",
    // Cellular - tx bytes
    "Min",
    "Avg",
    "Max",
    // Settings
    "Script",
    "Action",
    "Result",
]

const TerminalInfoTable = memo((props) => {
    const classes = useStyles()
    const {terminalInfo} = props
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, width: 1 }}  aria-label="a dense table">    
                <TableHead>
                    <TableRow>
                        {
                            columnsCategoryDatas.map(category => {
                                return (<TableCell align="center" colSpan={category.colSpan} className={classes.cell}>
                                            {category.title}
                                        </TableCell>)
                            })
                        }
                    </TableRow>
                    <TableRow>
                        {columnsNames.map(name => {
                            return (<TableCell align="center" className={classes.cell}>
                                        {name}
                                    </TableCell>)
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { Array.isArray(terminalInfo) ? terminalInfo.map((row) => {
                        return (
                            <TableRow hover>
                                <TableCell>{row.Model}</TableCell>
                                <TableCell>{row.SerialNumber}</TableCell>
                                <TableCell>{row.MAC}</TableCell>
                                <TableCell>{row.FirmwareVersion}</TableCell>
                                <TableCell>{row.Uptime}</TableCell>
                                <TableCell>{row.WAN_IP}</TableCell>
                                <TableCell>{row.Config}</TableCell>
                                <TableCell>{row.Time}</TableCell>

                                <TableCell>{row.Module}</TableCell>
                                <TableCell>{row.IMEI}</TableCell>
                                <TableCell>{row.MSISDN}</TableCell>
                                <TableCell>{row.NetworkType}</TableCell>
                                <TableCell>{row.MCC}</TableCell>
                                <TableCell>{row.MNC}</TableCell>
                                <TableCell>{row.TAC_LAC}</TableCell>
                                <TableCell>{row.CellID}</TableCell>

                                <TableCell>{row.RSSI_Min}</TableCell>
                                <TableCell>{row.RSSI_Avg}</TableCell>
                                <TableCell>{row.RSSI_Max}</TableCell>

                                <TableCell>{row.LTE_RSRP_Min}</TableCell>
                                <TableCell>{row.LTE_RSRP_Avg}</TableCell>
                                <TableCell>{row.LTE_RSRP_Max}</TableCell>

                                <TableCell>{row.NR_RSR_Min}</TableCell>
                                <TableCell>{row.NR_RSR_Avg}</TableCell>
                                <TableCell>{row.NR_RSR_Max}</TableCell>

                                <TableCell>{row.Channel}</TableCell>

                                {/* WiFi - ra0 rx bytes */}
                                <TableCell>{row.ra0_rx_bytes_Min}</TableCell>
                                <TableCell>{row.ra0_rx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra0_rx_bytes_Max}</TableCell>
                                {/* WiFi - ra0 tx bytes */}
                                <TableCell>{row.ra0_tx_bytes_Min}</TableCell>
                                <TableCell>{row.ra0_tx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra0_tx_bytes_Max}</TableCell>
                                {/* WiFi - ra0 clients */}
                                <TableCell>{row.ra0_clients_Min}</TableCell>
                                <TableCell>{row.ra0_clients_Avg}</TableCell>
                                <TableCell>{row.ra0_clients_Max}</TableCell>
                                {/* WiFi - ra1 rx bytes */}
                                <TableCell>{row.ra1_rx_bytes_Min}</TableCell>
                                <TableCell>{row.ra1_rx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra1_rx_bytes_Max}</TableCell>
                                {/* WiFi - ra1 tx bytes */}
                                <TableCell>{row.ra1_tx_bytes_Min}</TableCell>
                                <TableCell>{row.ra1_tx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra1_tx_bytes_Max}</TableCell>
                                {/* WiFi - ra1 clients */}
                                <TableCell>{row.ra1_clients_Min}</TableCell>
                                <TableCell>{row.ra1_clients_Avg}</TableCell>
                                <TableCell>{row.ra1_clients_Max}</TableCell>
                                {/* WiFi - ra2 rx bytes */}
                                <TableCell>{row.ra2_rx_bytes_Min}</TableCell>
                                <TableCell>{row.ra2_rx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra2_rx_bytes_Max}</TableCell>
                                {/* WiFi - ra2 tx bytes */}
                                <TableCell>{row.ra2_tx_bytes_Min}</TableCell>
                                <TableCell>{row.ra2_tx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra2_tx_bytes_Max}</TableCell>
                                {/* WiFi - ra2 clients */}
                                <TableCell>{row.ra2_clients_Min}</TableCell>
                                <TableCell>{row.ra2_clients_Avg}</TableCell>
                                <TableCell>{row.ra2_clients_Max}</TableCell>
                                {/* WiFi - ra3 rx bytes */}
                                <TableCell>{row.ra3_rx_bytes_Min}</TableCell>
                                <TableCell>{row.ra3_rx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra3_rx_bytes_Max}</TableCell>
                                {/* WiFi - ra3 tx bytes */}
                                <TableCell>{row.ra3_tx_bytes_Min}</TableCell>
                                <TableCell>{row.ra3_tx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra3_tx_bytes_Max}</TableCell>
                                {/* WiFi - ra3 clients */}
                                <TableCell>{row.ra3_clients_Min}</TableCell>
                                <TableCell>{row.ra3_clients_Avg}</TableCell>
                                <TableCell>{row.ra3_clients_Max}</TableCell>
                                {/* WiFi - ra4 rx bytes */}
                                <TableCell>{row.ra4_rx_bytes_Min}</TableCell>
                                <TableCell>{row.ra4_rx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra4_rx_bytes_Max}</TableCell>
                                {/* WiFi - ra4 tx bytes */}
                                <TableCell>{row.ra4_tx_bytes_Min}</TableCell>
                                <TableCell>{row.ra4_tx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra4_tx_bytes_Max}</TableCell>
                                {/* WiFi - ra4 clients */}
                                <TableCell>{row.ra4_clients_Min}</TableCell>
                                <TableCell>{row.ra4_clients_Avg}</TableCell>
                                <TableCell>{row.ra4_clients_Max}</TableCell>
                                {/* WiFi - ra5 rx bytes */}
                                <TableCell>{row.ra5_rx_bytes_Min}</TableCell>
                                <TableCell>{row.ra5_rx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra5_rx_bytes_Max}</TableCell>
                                {/* WiFi - ra5 tx bytes */}
                                <TableCell>{row.ra5_tx_bytes_Min}</TableCell>
                                <TableCell>{row.ra5_tx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra5_tx_bytes_Max}</TableCell>
                                {/* WiFi - ra5 clients */}
                                <TableCell>{row.ra5_clients_Min}</TableCell>
                                <TableCell>{row.ra5_clients_Avg}</TableCell>
                                <TableCell>{row.ra5_clients_Max}</TableCell>
                                {/* WiFi - ra6 rx bytes */}
                                <TableCell>{row.ra6_rx_bytes_Min}</TableCell>
                                <TableCell>{row.ra6_rx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra6_rx_bytes_Max}</TableCell>
                                {/* WiFi - ra6 tx bytes */}
                                <TableCell>{row.ra6_tx_bytes_Min}</TableCell>
                                <TableCell>{row.ra6_tx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra6_tx_bytes_Max}</TableCell>
                                {/* WiFi - ra6 clients */}
                                <TableCell>{row.ra6_clients_Min}</TableCell>
                                <TableCell>{row.ra6_clients_Avg}</TableCell>
                                <TableCell>{row.ra6_clients_Max}</TableCell>
                                {/* WiFi - ra7 rx bytes */}
                                <TableCell>{row.ra7_rx_bytes_Min}</TableCell>
                                <TableCell>{row.ra7_rx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra7_rx_bytes_Max}</TableCell>
                                {/* WiFi - ra7 tx bytes */}
                                <TableCell>{row.ra7_tx_bytes_Min}</TableCell>
                                <TableCell>{row.ra7_tx_bytes_Avg}</TableCell>
                                <TableCell>{row.ra7_tx_bytes_Max}</TableCell>
                                {/* WiFi - ra7 clients */}
                                <TableCell>{row.ra7_clients_Min}</TableCell>
                                <TableCell>{row.ra7_clients_Avg}</TableCell>
                                <TableCell>{row.ra7_clients_Max}</TableCell>

                                {/* Cellular - rx bytes */}
                                <TableCell>{row.rx_bytes_Min}</TableCell>
                                <TableCell>{row.rx_bytes_AVG}</TableCell>
                                <TableCell>{row.rx_bytes_Max}</TableCell>
                                {/* Cellular - tx bytes */}
                                <TableCell>{row.tx_bytes_Min}</TableCell>
                                <TableCell>{row.tx_bytes_AVG}</TableCell>
                                <TableCell>{row.tx_bytes_Max}</TableCell>
                                {/* Settings */}
                                <TableCell>{row.Script}</TableCell>
                                <TableCell>{row.Action}</TableCell>
                                <TableCell>{row.Result}</TableCell>
                            </TableRow>
                        )
                    }) : null}
                </TableBody>
            </Table>
        </TableContainer>
    )
})

export default TerminalInfoTable