import './App.css';
import { Auth, Hub } from 'aws-amplify';
import UserContext from './context/UserContext';
import Router from './Router';
import React from 'react';


class App extends React.Component {
  state = {
    currentUser: {},
    isLoaded: false
  }
  
  componentDidMount(){
    // amplify hub listener
    const listener = (data) => {
      // eslint-disable-next-line default-case
      switch(data.payload.event) {
        case 'signIn':
          this.updateCurrentUser();
          break;
        case 'signOut':
          this.updateCurrentUser();
          break;
      }
    }

    this.updateCurrentUser()
    Hub.listen('auth', listener);
  }

  updateCurrentUser = async (user) => {
    if (user) {
      this.setState({ currentUser: user })
      return
    }
    try {
      const user = await Auth.currentAuthenticatedUser()
      this.setState({ currentUser: user, isLoaded: true })
    } catch (err) {
      this.setState({ currentUser: null, isLoaded: true })
    }
  }

  getCurrentUserGroup = () => {
    return this.state.currentUser.signInUserSession.accessToken.payload["cognito:groups"]
  }
  render() {
    return(
      <UserContext.Provider value={{
        user: this.state.currentUser,
        updateCurrentUser: this.updateCurrentUser,
        getCurrentUserGroup: this.getCurrentUserGroup,
        isLoaded: this.state.isLoaded
      }}>
        <div className='App'>
          <Router />
        </div>
      </UserContext.Provider>
    );
  }
} 

export default App;