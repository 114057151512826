import React, { useContext, useEffect } from "react"
import { Button, makeStyles, Typography } from '@material-ui/core'
import {Box, TextField} from '@mui/material'
import MaterialTable from "material-table"
import Dialog from '@mui/material/Dialog'
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import TitleHeader from "../components/TitleHeader"
import UserContext from "../context/UserContext"

import { API, graphqlOperation } from "aws-amplify"
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'

const useStyles = makeStyles({
    root: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '90%',
        margin: '0 auto'
    },
    title: {
        marginBlock: '2%',
        marginBottom: '5%'
    },
    accordionContainer: {
        marginTop: '2%'
    },
    customAccordionSummary: {
        justifyContent: "flex-start"
    },
    textField: {
        marginBlock: '1%',
        align: 'left'
    },
    btnContainer: {
        marginTop: '1%',
        marginBottom: '1%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    btn: {
        marginRight: '1%'
    },
    cell: {
        borderRight: "0.5px solid #DEDEDE"
    }
})

// TODO: Admin権限を持つユーザグループ名定義方法考慮する必要あり
const ADMIN_USER_GROUP_NAME = process.env.REACT_APP_ADMIN_GID

/**
 * 端末登録ページ
 * @param {history} props 
 * @returns 
 */
const TerminalRegistration = (props) => {
    const classes = useStyles()
    const history = props.history
    const userGroups = useContext(UserContext).getCurrentUserGroup()
    const isAdmin = userGroups.includes(ADMIN_USER_GROUP_NAME)
    const [openAddTerminal, setOpenAddTerminal] = React.useState(false)
    const [openEditTerminal, setOpenEditTerminal] = React.useState(false)
    const [dialogText, setDialogText] = React.useState("")
    const [terminalList, setTerminalList] = React.useState([])
    const [editTerminalMac, setEditTerminalMac] = React.useState("")
    const [editTerminalSn, setEditTerminalSn] = React.useState("")
    const [editTerminalAddress, setEditTerminalAddress] = React.useState("")
    const [editTerminalLat, setEditTerminalLat] = React.useState("")
    const [editTerminalLong, setEditTerminalLong] = React.useState("")
    const [editTerminalGroupId, setEditTerminalGroupId] = React.useState("")

    useEffect(() => {
        const getRegisterdTerminalList = async() => {
            try {
                await getTableData()
            } catch (e) {
                console.log(e)
            }            
        }
        getRegisterdTerminalList()
    },[userGroups])

    const getTableData = async () => {
        if(isAdmin) {
            const items = await getRegisteredTerminal()
            setTerminalList(items)
        } else {
            const items = await getRegisteredTerminalsByGroupId()
            setTerminalList(items)
        }
    }

    const getRegisteredTerminal = async() => {
        let nextToken = null
        const retTerminalList = []
        do {
            try {
                const response = await API.graphql(graphqlOperation(queries.listRegisteredTerminals, {nextToken: nextToken, limit: 1000}))
                const termnalLatestData = response.data.listRegisteredTerminals.items
                nextToken = response.data.listRegisteredTerminals.nextToken
                retTerminalList.push(...termnalLatestData)
            } catch (e) {
                console.log(e)
                nextToken = null
            }
        } while (nextToken)
        return retTerminalList
    }

    const getRegisteredTerminalsByGroupId = async() => {
        let nextToken = null
        const retTerminalList = []
        do {
            try {
                const response = await API.graphql(graphqlOperation(queries.listRegisteredTerminals, {GroupId: userGroups[0], nextToken: nextToken, limit: 1000}))
                const termnalLatestData = response.data.listRegisteredTerminals.items
                nextToken = response.data.listRegisteredTerminals.nextToken
                retTerminalList.push(...termnalLatestData)
            } catch (e) {
                console.log(e)
                nextToken = null
            }
        } while (nextToken)
        return retTerminalList
    }
    
    const onClickBackBtn = () => {
        history.goBack()
    }
    const onClickEditBtn = (row) => {
        setEditTerminalGroupId(row.GroupId)
        setEditTerminalMac(row.MAC)
        setEditTerminalSn(row.SN)
        setEditTerminalAddress(row.Address)
        setEditTerminalLat(row.Lat)
        setEditTerminalLong(row.Lon)
        setDialogText('Please edit the terminal information.')
        setOpenEditTerminal(true)
    }

    const onClickAddBtn = () => {
        setEditTerminalMac('')
        setEditTerminalSn('')
        setEditTerminalAddress('')
        setEditTerminalLat('')
        setEditTerminalLong('')
        setDialogText('Enter the device information you want to add.')
        setOpenAddTerminal(true)
        setEditTerminalGroupId('')
    }

    /**
     * 端末登録情報追加ボタン押下
     */
    const onClickAddRegistrationBtn = async () => {
        const registTerminal = {
            MAC: editTerminalMac,
            SN: editTerminalSn,
            Address: editTerminalAddress,
            Lat: editTerminalLat,
            Lon: editTerminalLong,
            GroupId: editTerminalGroupId,
            Action: false,
            Result: "NONE"
        }
        try {
            await API.graphql({ query: mutations.registTerminal, variables: registTerminal})
            getTableData()
            setOpenAddTerminal(false)
        }catch (e) {
            // TODO: 登録失敗時の文言設定
            console.log(e)
        }
    }

    /**
     * 端末登録情報編集ボタン押下
     */
    const onClickEditRegistrationBtn = async() => {
        const updateTerminalInfo = {
            GroupId: editTerminalGroupId,
            MAC: editTerminalMac,
            SN: editTerminalSn,
            Address: editTerminalAddress,
            Lat: editTerminalLat,
            Lon: editTerminalLong
        }
        try {
            await API.graphql({ query: mutations.updateRegistTerminal, variables: updateTerminalInfo})
            getTableData()
            setOpenEditTerminal(false)
        }catch (e) {
            // TODO: 登録失敗時の文言設定
            console.log(e)
        }
    }
    
    /**
     * 追加ダイアログを閉じるボタン押下
     */
    const handleAddClose = () => {
        setOpenAddTerminal(false)
    }

    /**
     * 編集ダイアログを閉じるボタン押下
     */
    const handleEditClose = () => {
        setOpenEditTerminal(false)
    }

    /**
     * 編集ダイアログの削除ボタン押下
     */
    const handleDelete = async() => {
        const deleteTerminal = {
            GroupId: editTerminalGroupId,
            MAC: editTerminalMac
        }
        try {
            await API.graphql({ query: mutations.deleteRegisteredTerminal, variables:{input: deleteTerminal}})
            getTableData()
            setOpenEditTerminal(false)
        } catch (e) {
            console.log(e)
        }
    }

    const handleChangeMac = (event) => {
        setEditTerminalMac(event.target.value)
    }
    const handleChangeSn = (event) => {
        setEditTerminalSn(event.target.value)
    }
    const handleChangeAddress = (event) => {
        setEditTerminalAddress(event.target.value)
    }
    const handleChangeLat = (event) => {
        setEditTerminalLat(event.target.value)
    }
    const handleChangeLong = (event) => {
        setEditTerminalLong(event.target.value)
    }
    const handleChangeGroupId = (event) => {
        setEditTerminalGroupId(event.target.value)
    }

    const columns = [
        {
            title: 'MAC Address',
            field: 'MAC'
        },
        {
            title: 'Serial Number',
            field: 'SN'
        },
        {
            title: 'Address',
            field: 'Address'
        },
        {
            title: 'Latitude',
            field: 'Lat'
        },
        {
            title: 'Longitude',
            field: 'Lon'
        }
    ]

    const actions = [
        {
            icon: 'edit',
            tooltip: 'Edit terminal info',
            position: 'row',
            onClick: (e, row) => onClickEditBtn(row)
        }
    ]

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <TitleHeader history={props.history}/>
                {isAdmin && (
                    <div style={{display: 'flex', marginBlock: '10px', justifyContent: 'flex-end'}} >
                        <Button variant="outlined" onClick={onClickAddBtn}>
                            Add Device
                        </Button>
                    </div>
                    )
                }
                
                <Box>
                    <link
                        rel="stylesheet"
                        href="https://fonts.googleapis.com/icon?family=Material+Icons"
                        />
                    <MaterialTable
                        title={''}
                        columns={columns}
                        data={terminalList}
                        actions={actions}
                        localization={{
                            header: {actions: 'Edit' }
                        }}
                    />
                </Box>
            </Box>
            <Button variant="contained" style={{marginTop: '30px'}} onClick={onClickBackBtn}>Back</Button>
            <Dialog open={openAddTerminal} onClose={handleAddClose}>
                <DialogTitle>Terminal Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogText}</DialogContentText>
                    <TextField
                        margin="normal"
                        id="mac"
                        label="Mac Address"
                        type='text'
                        fullWidth
                        variant="outlined"
                        value={editTerminalMac}
                        onChange={handleChangeMac}
                    />
                    <TextField
                        margin="normal"
                        id="sn"
                        label="Serial Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalSn}
                        onChange={handleChangeSn}
                    />
                    <TextField
                        margin="normal"
                        id="address"
                        label="Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalAddress}
                        onChange={handleChangeAddress}
                    />
                    <TextField
                        margin="normal"
                        id="latitude"
                        label="Latitude"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalLat}
                        onChange={handleChangeLat}
                    />
                    <TextField
                        margin="normal"
                        id="longitude"
                        label="Longitude"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalLong}
                        onChange={handleChangeLong}
                    />
                    <TextField
                        margin="normal"
                        id="groupId"
                        label="Group Id"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalGroupId}
                        onChange={handleChangeGroupId}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddClose} variant='outlined'>Cancel</Button>
                    <Button onClick={onClickAddRegistrationBtn} variant='outlined'>Registration</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openEditTerminal} onClose={handleEditClose}>
                <DialogTitle>Device Info</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogText}</DialogContentText>
                    <Typography variant='h6' align='left'>MAC: {editTerminalMac}</Typography>
                    <TextField
                        margin="normal"
                        id="sn"
                        label="Serial Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalSn}
                        onChange={handleChangeSn}
                    />
                    <TextField
                        margin="normal"
                        id="address"
                        label="Address"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalAddress}
                        onChange={handleChangeAddress}
                    />
                    <TextField
                        margin="normal"
                        id="latitude"
                        label="Latitude"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalLat}
                        onChange={handleChangeLat}
                    />
                    <TextField
                        margin="normal"
                        id="longitude"
                        label="Longitude"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={editTerminalLong}
                        onChange={handleChangeLong}
                    />
                    <DialogActions style={{justifyContent: 'space-between', paddingInline: '0'}}>
                        <Button onClick={handleDelete} variant='outlined' color='secondary'>Delete</Button>
                        <div>
                            <Button onClick={handleEditClose} variant='outlined' style={{marginRight: '10px'}}>Cancel</Button>
                            <Button onClick={onClickEditRegistrationBtn} variant='outlined'>Registration</Button>
                        </div>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default TerminalRegistration