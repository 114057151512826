import { Button, Toolbar } from '@mui/material'
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import ChevronRight from "@mui/icons-material/ChevronRight"
import RouterSettingPage from '../page/RouterSettingPage'

const TerminalInfoPagination = (props) => {
    const {nextNextToken, previousTokens, setNextToken, setPreviousTokens, nextToken} = props
    // 1ページ分しかデータがない場合
    if(!nextNextToken && previousTokens.length === 0) {
       return null
    }
    return (
        <Toolbar>
            {previousTokens.length > 0 && (
                <Button 
                    startIcon={<ChevronLeft/>}
                    onClick={() => {
                        setNextToken(previousTokens.slice(-1)[0])
                        previousTokens.pop()
                        setPreviousTokens(previousTokens)
                    }}>Previous</Button>
            )}
            {nextNextToken && (
                <Button 
                    endIcon={<ChevronRight/>}
                    onClick={() => {
                        setPreviousTokens([...previousTokens, nextToken])
                        setNextToken(nextNextToken)
                    }}>Next</Button>
            )}
        </Toolbar>
    )
}

export default TerminalInfoPagination