/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRegisteredTerminal = /* GraphQL */ `
  query GetRegisteredTerminal($GroupId: ID!, $MAC: ID!) {
    getRegisteredTerminal(GroupId: $GroupId, MAC: $MAC) {
      GroupId
      MAC
      Model
      SN
      Firmware
      Uptime
      WanIp
      CellularModule
      IMEI
      MSISDN
      NetType
      RSSI
      LTERSRP
      NRRSRP
      MCC
      MNC
      Result
      Action
      Time
      LastUpdated
      Address
      Lat
      Lon
      ScriptPath
      Config
      createdAt
      updatedAt
      TerminalInfo {
        items {
          MAC
          Order
          SerialNumber
          Model
          FirmwareVersion
          Uptime
          WAN_IP
          Module
          IMEI
          MSISDN
          Config
          NetworkType
          RSSI_Min
          RSSI_Avg
          RSSI_Max
          LTE_RSRP_Min
          LTE_RSRP_Avg
          LTE_RSRP_Max
          NR_RSR_Min
          NR_RSR_Avg
          NR_RSR_Max
          MCC
          MNC
          TAC_LAC
          CellID
          Channel
          ra0_rx_bytes_Min
          ra0_rx_bytes_Avg
          ra0_rx_bytes_Max
          ra0_tx_bytes_Min
          ra0_tx_bytes_Avg
          ra0_tx_bytes_Max
          ra0_clients_Min
          ra0_clients_Avg
          ra0_clients_Max
          ra1_rx_bytes_Min
          ra1_rx_bytes_Avg
          ra1_rx_bytes_Max
          ra1_tx_bytes_Min
          ra1_tx_bytes_Avg
          ra1_tx_bytes_Max
          ra1_clients_Min
          ra1_clients_Avg
          ra1_clients_Max
          ra2_rx_bytes_Min
          ra2_rx_bytes_Avg
          ra2_rx_bytes_Max
          ra2_tx_bytes_Min
          ra2_tx_bytes_Avg
          ra2_tx_bytes_Max
          ra2_clients_Min
          ra2_clients_Avg
          ra2_clients_Max
          ra3_rx_bytes_Min
          ra3_rx_bytes_Avg
          ra3_rx_bytes_Max
          ra3_tx_bytes_Min
          ra3_tx_bytes_Avg
          ra3_tx_bytes_Max
          ra3_clients_Min
          ra3_clients_Avg
          ra3_clients_Max
          ra4_rx_bytes_Min
          ra4_rx_bytes_Avg
          ra4_rx_bytes_Max
          ra4_tx_bytes_Min
          ra4_tx_bytes_Avg
          ra4_tx_bytes_Max
          ra4_clients_Min
          ra4_clients_Avg
          ra4_clients_Max
          ra5_rx_bytes_Min
          ra5_rx_bytes_Avg
          ra5_rx_bytes_Max
          ra5_tx_bytes_Min
          ra5_tx_bytes_Avg
          ra5_tx_bytes_Max
          ra5_clients_Min
          ra5_clients_Avg
          ra5_clients_Max
          ra6_rx_bytes_Min
          ra6_rx_bytes_Avg
          ra6_rx_bytes_Max
          ra6_tx_bytes_Min
          ra6_tx_bytes_Avg
          ra6_tx_bytes_Max
          ra6_clients_Min
          ra6_clients_Avg
          ra6_clients_Max
          ra7_rx_bytes_Min
          ra7_rx_bytes_Avg
          ra7_rx_bytes_Max
          ra7_tx_bytes_Min
          ra7_tx_bytes_Avg
          ra7_tx_bytes_Max
          ra7_clients_Min
          ra7_clients_Avg
          ra7_clients_Max
          rx_bytes_Min
          rx_bytes_AVG
          rx_bytes_Max
          tx_bytes_Min
          tx_bytes_AVG
          tx_bytes_Max
          Time
          Script
          Action
          Result
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listRegisteredTerminals = /* GraphQL */ `
  query ListRegisteredTerminals(
    $GroupId: ID
    $MAC: ModelIDKeyConditionInput
    $filter: ModelRegisteredTerminalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegisteredTerminals(
      GroupId: $GroupId
      MAC: $MAC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        GroupId
        MAC
        Model
        SN
        Firmware
        Uptime
        WanIp
        CellularModule
        IMEI
        MSISDN
        NetType
        RSSI
        LTERSRP
        NRRSRP
        MCC
        MNC
        Result
        Action
        Time
        LastUpdated
        Address
        Lat
        Lon
        ScriptPath
        Config
        TerminalInfo {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const registeredTerminalByMac = /* GraphQL */ `
  query RegisteredTerminalByMac(
    $MAC: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRegisteredTerminalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registeredTerminalByMac(
      MAC: $MAC
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        GroupId
        MAC
        Model
        SN
        Firmware
        Uptime
        WanIp
        CellularModule
        IMEI
        MSISDN
        NetType
        RSSI
        LTERSRP
        NRRSRP
        MCC
        MNC
        Result
        Action
        Time
        LastUpdated
        Address
        Lat
        Lon
        ScriptPath
        Config
        TerminalInfo {
          items {
            MAC
            Order
            SerialNumber
            Model
            FirmwareVersion
            Uptime
            WAN_IP
            Module
            IMEI
            MSISDN
            Config
            NetworkType
            RSSI_Min
            RSSI_Avg
            RSSI_Max
            LTE_RSRP_Min
            LTE_RSRP_Avg
            LTE_RSRP_Max
            NR_RSR_Min
            NR_RSR_Avg
            NR_RSR_Max
            MCC
            MNC
            TAC_LAC
            CellID
            Channel
            ra0_rx_bytes_Min
            ra0_rx_bytes_Avg
            ra0_rx_bytes_Max
            ra0_tx_bytes_Min
            ra0_tx_bytes_Avg
            ra0_tx_bytes_Max
            ra0_clients_Min
            ra0_clients_Avg
            ra0_clients_Max
            ra1_rx_bytes_Min
            ra1_rx_bytes_Avg
            ra1_rx_bytes_Max
            ra1_tx_bytes_Min
            ra1_tx_bytes_Avg
            ra1_tx_bytes_Max
            ra1_clients_Min
            ra1_clients_Avg
            ra1_clients_Max
            ra2_rx_bytes_Min
            ra2_rx_bytes_Avg
            ra2_rx_bytes_Max
            ra2_tx_bytes_Min
            ra2_tx_bytes_Avg
            ra2_tx_bytes_Max
            ra2_clients_Min
            ra2_clients_Avg
            ra2_clients_Max
            ra3_rx_bytes_Min
            ra3_rx_bytes_Avg
            ra3_rx_bytes_Max
            ra3_tx_bytes_Min
            ra3_tx_bytes_Avg
            ra3_tx_bytes_Max
            ra3_clients_Min
            ra3_clients_Avg
            ra3_clients_Max
            ra4_rx_bytes_Min
            ra4_rx_bytes_Avg
            ra4_rx_bytes_Max
            ra4_tx_bytes_Min
            ra4_tx_bytes_Avg
            ra4_tx_bytes_Max
            ra4_clients_Min
            ra4_clients_Avg
            ra4_clients_Max
            ra5_rx_bytes_Min
            ra5_rx_bytes_Avg
            ra5_rx_bytes_Max
            ra5_tx_bytes_Min
            ra5_tx_bytes_Avg
            ra5_tx_bytes_Max
            ra5_clients_Min
            ra5_clients_Avg
            ra5_clients_Max
            ra6_rx_bytes_Min
            ra6_rx_bytes_Avg
            ra6_rx_bytes_Max
            ra6_tx_bytes_Min
            ra6_tx_bytes_Avg
            ra6_tx_bytes_Max
            ra6_clients_Min
            ra6_clients_Avg
            ra6_clients_Max
            ra7_rx_bytes_Min
            ra7_rx_bytes_Avg
            ra7_rx_bytes_Max
            ra7_tx_bytes_Min
            ra7_tx_bytes_Avg
            ra7_tx_bytes_Max
            ra7_clients_Min
            ra7_clients_Avg
            ra7_clients_Max
            rx_bytes_Min
            rx_bytes_AVG
            rx_bytes_Max
            tx_bytes_Min
            tx_bytes_AVG
            tx_bytes_Max
            Time
            Script
            Action
            Result
          }
        }
      }
      nextToken
    }
  }
`;
export const getTerminalInfo = /* GraphQL */ `
  query GetTerminalInfo($MAC: ID!, $Order: Int!) {
    getTerminalInfo(MAC: $MAC, Order: $Order) {
      MAC
      Order
      SerialNumber
      Model
      FirmwareVersion
      Uptime
      WAN_IP
      Module
      IMEI
      MSISDN
      Config
      NetworkType
      RSSI_Min
      RSSI_Avg
      RSSI_Max
      LTE_RSRP_Min
      LTE_RSRP_Avg
      LTE_RSRP_Max
      NR_RSR_Min
      NR_RSR_Avg
      NR_RSR_Max
      MCC
      MNC
      TAC_LAC
      CellID
      Channel
      ra0_rx_bytes_Min
      ra0_rx_bytes_Avg
      ra0_rx_bytes_Max
      ra0_tx_bytes_Min
      ra0_tx_bytes_Avg
      ra0_tx_bytes_Max
      ra0_clients_Min
      ra0_clients_Avg
      ra0_clients_Max
      ra1_rx_bytes_Min
      ra1_rx_bytes_Avg
      ra1_rx_bytes_Max
      ra1_tx_bytes_Min
      ra1_tx_bytes_Avg
      ra1_tx_bytes_Max
      ra1_clients_Min
      ra1_clients_Avg
      ra1_clients_Max
      ra2_rx_bytes_Min
      ra2_rx_bytes_Avg
      ra2_rx_bytes_Max
      ra2_tx_bytes_Min
      ra2_tx_bytes_Avg
      ra2_tx_bytes_Max
      ra2_clients_Min
      ra2_clients_Avg
      ra2_clients_Max
      ra3_rx_bytes_Min
      ra3_rx_bytes_Avg
      ra3_rx_bytes_Max
      ra3_tx_bytes_Min
      ra3_tx_bytes_Avg
      ra3_tx_bytes_Max
      ra3_clients_Min
      ra3_clients_Avg
      ra3_clients_Max
      ra4_rx_bytes_Min
      ra4_rx_bytes_Avg
      ra4_rx_bytes_Max
      ra4_tx_bytes_Min
      ra4_tx_bytes_Avg
      ra4_tx_bytes_Max
      ra4_clients_Min
      ra4_clients_Avg
      ra4_clients_Max
      ra5_rx_bytes_Min
      ra5_rx_bytes_Avg
      ra5_rx_bytes_Max
      ra5_tx_bytes_Min
      ra5_tx_bytes_Avg
      ra5_tx_bytes_Max
      ra5_clients_Min
      ra5_clients_Avg
      ra5_clients_Max
      ra6_rx_bytes_Min
      ra6_rx_bytes_Avg
      ra6_rx_bytes_Max
      ra6_tx_bytes_Min
      ra6_tx_bytes_Avg
      ra6_tx_bytes_Max
      ra6_clients_Min
      ra6_clients_Avg
      ra6_clients_Max
      ra7_rx_bytes_Min
      ra7_rx_bytes_Avg
      ra7_rx_bytes_Max
      ra7_tx_bytes_Min
      ra7_tx_bytes_Avg
      ra7_tx_bytes_Max
      ra7_clients_Min
      ra7_clients_Avg
      ra7_clients_Max
      rx_bytes_Min
      rx_bytes_AVG
      rx_bytes_Max
      tx_bytes_Min
      tx_bytes_AVG
      tx_bytes_Max
      Time
      Script
      Action
      Result
      createdAt
      updatedAt
    }
  }
`;
export const listTerminalInfos = /* GraphQL */ `
  query ListTerminalInfos(
    $MAC: ID
    $Order: ModelIntKeyConditionInput
    $filter: ModelTerminalInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTerminalInfos(
      MAC: $MAC
      Order: $Order
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        MAC
        Order
        SerialNumber
        Model
        FirmwareVersion
        Uptime
        WAN_IP
        Module
        IMEI
        MSISDN
        Config
        NetworkType
        RSSI_Min
        RSSI_Avg
        RSSI_Max
        LTE_RSRP_Min
        LTE_RSRP_Avg
        LTE_RSRP_Max
        NR_RSR_Min
        NR_RSR_Avg
        NR_RSR_Max
        MCC
        MNC
        TAC_LAC
        CellID
        Channel
        ra0_rx_bytes_Min
        ra0_rx_bytes_Avg
        ra0_rx_bytes_Max
        ra0_tx_bytes_Min
        ra0_tx_bytes_Avg
        ra0_tx_bytes_Max
        ra0_clients_Min
        ra0_clients_Avg
        ra0_clients_Max
        ra1_rx_bytes_Min
        ra1_rx_bytes_Avg
        ra1_rx_bytes_Max
        ra1_tx_bytes_Min
        ra1_tx_bytes_Avg
        ra1_tx_bytes_Max
        ra1_clients_Min
        ra1_clients_Avg
        ra1_clients_Max
        ra2_rx_bytes_Min
        ra2_rx_bytes_Avg
        ra2_rx_bytes_Max
        ra2_tx_bytes_Min
        ra2_tx_bytes_Avg
        ra2_tx_bytes_Max
        ra2_clients_Min
        ra2_clients_Avg
        ra2_clients_Max
        ra3_rx_bytes_Min
        ra3_rx_bytes_Avg
        ra3_rx_bytes_Max
        ra3_tx_bytes_Min
        ra3_tx_bytes_Avg
        ra3_tx_bytes_Max
        ra3_clients_Min
        ra3_clients_Avg
        ra3_clients_Max
        ra4_rx_bytes_Min
        ra4_rx_bytes_Avg
        ra4_rx_bytes_Max
        ra4_tx_bytes_Min
        ra4_tx_bytes_Avg
        ra4_tx_bytes_Max
        ra4_clients_Min
        ra4_clients_Avg
        ra4_clients_Max
        ra5_rx_bytes_Min
        ra5_rx_bytes_Avg
        ra5_rx_bytes_Max
        ra5_tx_bytes_Min
        ra5_tx_bytes_Avg
        ra5_tx_bytes_Max
        ra5_clients_Min
        ra5_clients_Avg
        ra5_clients_Max
        ra6_rx_bytes_Min
        ra6_rx_bytes_Avg
        ra6_rx_bytes_Max
        ra6_tx_bytes_Min
        ra6_tx_bytes_Avg
        ra6_tx_bytes_Max
        ra6_clients_Min
        ra6_clients_Avg
        ra6_clients_Max
        ra7_rx_bytes_Min
        ra7_rx_bytes_Avg
        ra7_rx_bytes_Max
        ra7_tx_bytes_Min
        ra7_tx_bytes_Avg
        ra7_tx_bytes_Max
        ra7_clients_Min
        ra7_clients_Avg
        ra7_clients_Max
        rx_bytes_Min
        rx_bytes_AVG
        rx_bytes_Max
        tx_bytes_Min
        tx_bytes_AVG
        tx_bytes_Max
        Time
        Script
        Action
        Result
      }
      nextToken
    }
  }
`;

// ----------------------------------------------------------------
// 自作queries
// ----------------------------------------------------------------

/**
 * 端末最新情報取得（Modelの有無で端末最新情報の有無を判断する）
 */
 export const listTerminalLatestDataByGroupId = /* GraphQL */ `
 query MyQuery($GroupId: ID!, $nextToken: String) {
   listRegisteredTerminals(GroupId: $GroupId, limit: 1000, nextToken: $nextToken, filter: {Model: {attributeExists: true}}) {
     items {
       Model
       MAC
       SN
       Firmware
       Uptime
       WanIp
       CellularModule
       IMEI
       MSISDN
       NetType
       RSSI
       LTERSRP
       NRRSRP
       MCC
       MNC
       Time
       Action
       Result
     }
    nextToken 
   }
 }`;
 
 /**
  * 端末最新情報取得（Modelの有無で端末最新情報の有無を判断する）
  */
 export const listTerminalLatestData = /* GraphQL */ `
   query ListRegisteredTerminals(
     $filter: ModelRegisteredTerminalFilterInput
     $limit: Int
     $nextToken: String
   ) {
     listRegisteredTerminals(
       filter: $filter
       limit: $limit
       nextToken: $nextToken
     ) {
       items {
         Model
         MAC
         SN
         Firmware
         Uptime
         WanIp
         CellularModule
         IMEI
         MSISDN
         NetType
         RSSI
         LTERSRP
         NRRSRP
         MCC
         MNC
         Time
         Action
         Result
       }
       nextToken
     }
   }
 `;
  
 /**
  * 端末設定画面表示情報取得
  */
export const listTerminalManagementScreenInfosByMac = /* GraphQL */ `
query MyQuery(
  $MAC: ID
  $sortDirection: ModelSortDirection
  $filter: ModelRegisteredTerminalFilterInput
  $limit: Int
  $nextToken: String
  $terminalInfoLimit: Int
  $terminalInfoNextToken: String
) {
  registeredTerminalByMac(
    MAC: $MAC
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      GroupId
      MAC
      Model
      Action
      ScriptPath
      Config
      TerminalInfo(limit: $terminalInfoLimit, nextToken: $terminalInfoNextToken, sortDirection: DESC) {
        items {
          MAC
          Order
          SerialNumber
          Model
          FirmwareVersion
          Uptime
          WAN_IP
          Module
          IMEI
          MSISDN
          Config
          NetworkType
          RSSI_Min
          RSSI_Avg
          RSSI_Max
          LTE_RSRP_Min
          LTE_RSRP_Avg
          LTE_RSRP_Max
          NR_RSR_Min
          NR_RSR_Avg
          NR_RSR_Max
          MCC
          MNC
          TAC_LAC
          CellID
          Channel
          ra0_rx_bytes_Min
          ra0_rx_bytes_Avg
          ra0_rx_bytes_Max
          ra0_tx_bytes_Min
          ra0_tx_bytes_Avg
          ra0_tx_bytes_Max
          ra0_clients_Min
          ra0_clients_Avg
          ra0_clients_Max
          ra1_rx_bytes_Min
          ra1_rx_bytes_Avg
          ra1_rx_bytes_Max
          ra1_tx_bytes_Min
          ra1_tx_bytes_Avg
          ra1_tx_bytes_Max
          ra1_clients_Min
          ra1_clients_Avg
          ra1_clients_Max
          ra2_rx_bytes_Min
          ra2_rx_bytes_Avg
          ra2_rx_bytes_Max
          ra2_tx_bytes_Min
          ra2_tx_bytes_Avg
          ra2_tx_bytes_Max
          ra2_clients_Min
          ra2_clients_Avg
          ra2_clients_Max
          ra3_rx_bytes_Min
          ra3_rx_bytes_Avg
          ra3_rx_bytes_Max
          ra3_tx_bytes_Min
          ra3_tx_bytes_Avg
          ra3_tx_bytes_Max
          ra3_clients_Min
          ra3_clients_Avg
          ra3_clients_Max
          ra4_rx_bytes_Min
          ra4_rx_bytes_Avg
          ra4_rx_bytes_Max
          ra4_tx_bytes_Min
          ra4_tx_bytes_Avg
          ra4_tx_bytes_Max
          ra4_clients_Min
          ra4_clients_Avg
          ra4_clients_Max
          ra5_rx_bytes_Min
          ra5_rx_bytes_Avg
          ra5_rx_bytes_Max
          ra5_tx_bytes_Min
          ra5_tx_bytes_Avg
          ra5_tx_bytes_Max
          ra5_clients_Min
          ra5_clients_Avg
          ra5_clients_Max
          ra6_rx_bytes_Min
          ra6_rx_bytes_Avg
          ra6_rx_bytes_Max
          ra6_tx_bytes_Min
          ra6_tx_bytes_Avg
          ra6_tx_bytes_Max
          ra6_clients_Min
          ra6_clients_Avg
          ra6_clients_Max
          ra7_rx_bytes_Min
          ra7_rx_bytes_Avg
          ra7_rx_bytes_Max
          ra7_tx_bytes_Min
          ra7_tx_bytes_Avg
          ra7_tx_bytes_Max
          ra7_clients_Min
          ra7_clients_Avg
          ra7_clients_Max
          rx_bytes_Min
          rx_bytes_AVG
          rx_bytes_Max
          tx_bytes_Min
          tx_bytes_AVG
          tx_bytes_Max
          Time
          Script
          Action
          Result
        }
        nextToken
      }
    }
    nextToken
  }
 }`;
 
 /**
  * 地図画面用データ取得
  */
export const listTerminalMapScreenData = /* GraphQL */ `
   query ListRegisteredTerminals(
     $filter: ModelRegisteredTerminalFilterInput
     $limit: Int
     $nextToken: String
   ) {
     listRegisteredTerminals(
       filter: $filter
       limit: $limit
       nextToken: $nextToken
     ) {
       items {
        Model
        MAC
        SN
        Firmware
        Uptime
        WanIp
        CellularModule
        IMEI
        MSISDN
        NetType
        RSSI
        LTERSRP
        NRRSRP
        MCC
        MNC
        Time
        Action
        Result
        Lat
        Lon
       }
       nextToken
     }
   }
 `;

 /**
  * GroupIdから地図画面用データ取得
  */
export const listTerminalMapScreenDataByGroupId = /* GraphQL */ `
  query MyQuery($GroupId: ID!, $nextToken: String) {
    listRegisteredTerminals(GroupId: $GroupId, limit: 1000, nextToken: $nextToken, filter: {Model: {attributeExists: true}}) {
      items {
        Model
        MAC
        SN
        Firmware
        Uptime
        WanIp
        CellularModule
        IMEI
        MSISDN
        NetType
        RSSI
        LTERSRP
        NRRSRP
        MCC
        MNC
        Time
        Action
        Result
        Lat
        Lon
      }
      nextToken
    }
  }`;