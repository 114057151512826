import React, { useState, useEffect, useContext } from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Backdrop, CircularProgress } from '@mui/material'
import { Button } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import * as queries from '../graphql/queries'

import TerminalListTable from "../components/TerminalListTable";
import TitleHeader from "../components/TitleHeader";

import UserContext from "../context/UserContext"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '90%',
        margin: '0 auto'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    } 
}))

/**
 * 端末管理ページ
 * @param {*} props 
 * @returns 
 */
const OTAServerManagementPage = (props) => {
    // ユーザグループ取得
    const ADMIN_USER_GROUP_NAME = process.env.REACT_APP_ADMIN_GID
    const userGroups = useContext(UserContext).getCurrentUserGroup()
    const isAdmin = userGroups.includes(ADMIN_USER_GROUP_NAME)

    const classes = useStyles()
    const [dispErrSettingsBtnMsg, setDispErrSettingsBtnMsg] = useState()
    const [terminalList, setTerminalList] = useState([])
    const [open, setOpen] = useState(false)
    useEffect(() => {
        // 端末最新情報取得
        const getManagementTerminalList = async() => {
            const latestData = await allTerminalRequestByGroupId()
            setTerminalList(latestData)
        }

        // 端末最新情報全件取得
        const getManagementTerminalListAdmin = async() => {
            const latestData = await allTerminalRequest()
            setTerminalList(latestData)
        }
        if(isAdmin) {
            // 全ての端末情報を取得する
            getManagementTerminalListAdmin()
        } else {
            // GroupIdの当てはまる端末情報を取得する。
            getManagementTerminalList()
        }
        
    }, [userGroups])

    const allTerminalRequest = async() => {
        let nextToken = null
        const retTerminalList = []
        setOpen(true)
        do {
            try {
                const response = await API.graphql(graphqlOperation(queries.listTerminalLatestData, {nextToken: nextToken, limit: 1000, filter: {Model: {attributeType: "string"}}}))
                const termnalLatestData = response.data.listRegisteredTerminals.items
                nextToken = response.data.listRegisteredTerminals.nextToken
                retTerminalList.push(...termnalLatestData)
            } catch (e) {
                console.log(e)
                nextToken = null
            }
        } while (nextToken)
        setOpen(false)
        return retTerminalList
    }

    const allTerminalRequestByGroupId = async() => {
        let nextToken = null
        const retTerminalList = []
        setOpen(true)
        do {
            try {
                const response = await API.graphql(graphqlOperation(queries.listTerminalLatestDataByGroupId, {GroupId: userGroups[0], nextToken: nextToken}))
                const termnalLatestData = response.data.listRegisteredTerminals.items
                nextToken = response.data.listRegisteredTerminals.nextToken
                retTerminalList.push(...termnalLatestData)
            } catch (e) {
                console.log(e)
                nextToken = null
            }
        } while (nextToken)
        setOpen(false)
        return retTerminalList
    }
    const onClickSettingsBtn = () => {
        const selectedList = terminalList.filter(row => row.tableData.checked).map(row => row['MAC'])
        if(selectedList.length) {
            setDispErrSettingsBtnMsg(false)
            props.history.push({pathname: '/setting', checkedMACList: selectedList})
        } else {
            setDispErrSettingsBtnMsg(true)
        }
    }
    const onClickMapBtn = async() => {
        props.history.push({pathname: '/map'})
    }

    const onClickTerminalRegistration = () => {
        props.history.push({pathname: '/registration'})
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <TitleHeader history={props.history}/>
                <Box sx={
                    {justifyContent: 'flex-start', alignItems: 'flex-start'}
                } style={{display: 'flex', marginBlock: '10px'}}>
                    <Button variant="outlined" onClick={onClickSettingsBtn}>Settings for selected items</Button>
                    <Button variant="outlined" onClick={onClickTerminalRegistration} style={{marginLeft: '10px'}}>DEVICE Registration</Button>
                    <Button variant="outlined" onClick={onClickMapBtn}  style={{marginLeft: '10px'}}>Device Map</Button>
                </Box>
                {dispErrSettingsBtnMsg && <Alert severity="error">Please select one or more</Alert>}                
                <TerminalListTable terminalList={[...terminalList]} history={props.history} />
            </Box>
            <Backdrop open={open} className={classes.backdrop} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default OTAServerManagementPage;