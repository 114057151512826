import React from 'react'
import Amplify ,{ Auth } from 'aws-amplify'
import UserContext from '../context/UserContext'
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

/**
 * サインインページ
 */
class SignInPage extends React.Component {
    state = {
        username: '',
        password: '',
        user: {},
        authCode: '',
        isError: false
    }
    static contextType = UserContext
    onChange = (key, value) => {
        this.props.updateErrorMessage(null)
        this.setState({
            [key]: value
        })
    }
    signIn = () => {
        const { history } = this.props
        const { updateCurrentUser } = this.context
        Auth.signIn(this.state.username, this.state.password)
            .then(user => {
                if(user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    Auth.completeNewPassword(user, this.state.password);
                }
                if (!user.signInUserSession) {
                    console.log(user.signInUserSession);
                    this.setState({ user, showConfirmation: true })
                } else {
                    updateCurrentUser(user)
                    // history.push('/dashboard')
                }
                history.push('/manage')
            })
            .catch(err => {
                console.log('error signing in...: ', err)
                this.setState({isError: true})
                this.props.updateErrorMessage(err.message)
            })
        
    }
    
    render() {
        return (
            <Grid container justifyContent='center'>
                <div style={{height: "100%", width: "100%", justifyContent: "center", alignItems: "center", flexGrow: 1}}>
                    <h2>IDY OTA Device Management System</h2>
                    <Box margin={5}>                    
                        <TextField 
                            style={{ width: '20%' }}
                            label="メールアドレス"
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MailOutlineOutlinedIcon />
                                </InputAdornment>
                            ),
                            }}
                            variant="standard"
                            onChange={evt => this.onChange('username', evt.target.value)}
                        />
                    </Box>
                    <Box margin={5}>
                        <TextField 
                            style={{ width: '20%' }}
                            label="パスワード"
                            InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockOutlinedIcon />
                                </InputAdornment>
                            ),
                            }}
                            variant="standard"
                            onChange={evt => this.onChange('password', evt.target.value)}
                            type="password"
                        />
                    </Box>
                    <Button onClick={this.signIn} style={{margin: '10px'}}>サインイン</Button>
                    { this.state.isError && <Typography style={{color: 'red'}}>サインインに失敗しました。</Typography> }
                </div>
            </Grid>
        )
    }
}


export default SignInPage;