import React, { useState, useEffect, useContext } from "react"
import { Box, Typography, TextField, Grid, Backdrop, CircularProgress } from '@mui/material'
import { Button, makeStyles } from '@material-ui/core'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableContainer,
    TableRow,
    TableBody,
    TableCell,
    Paper
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TitleHeader from "../components/TitleHeader"
import UserContext from "../context/UserContext"
import TerminalInfoTable from "../components/TerminalInfoTable"
import TerminalInfoPagination from "../components/TerminalInfoPagination"
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'

const useStyles = makeStyles({
    root: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center'
    },
    container: {
        width: '90%',
        margin: '0 auto'
    },
    title: {
        marginBlock: '2%',
        marginBottom: '5%'
    },
    accordionContainer: {
        marginTop: '2%'
    },
    customAccordionSummary: {
        justifyContent: "flex-start"
    },
    textField: {
        marginBlock: '1%',
        align: 'left'
    },
    btnContainer: {
        marginTop: '1%',
        marginBottom: '1%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    btn: {
        marginRight: '1%'
    },
    cell: {
        borderRight: "0.5px solid #DEDEDE"
    }
})

/**
 * 端末設定ページ
 * @component
 */
const RouterSettingPage = (props) => {
    const classes = useStyles()
    const userGroup = useContext(UserContext).getCurrentUserGroup()[0]

    const { history } = props
    const {checkedMACList, mac} = props.location
    const [dispMac, setDispMac] = useState(mac)
    const [terminalInfo, setTerminalInfo] = useState([])
    const [config, setConfig] = useState({})
    const [terminalGroupId, setTerminalGroupId] = useState(userGroup)
    const [primaryKeyList, setPrimarykeyList] = useState([])
    const [scriptAction, setScriptAction] = useState("")
    const [scriptFileName, setScriptFileName] = useState("")
    const [open, setOpen] = useState(false)

    const [nextToken, setNextToken] = useState(undefined)
    const [nextNextToken, setNextNextToken] = useState()
    const [previousTokens, setPreviousTokens] = useState([])

    const isSingleTerminalSettingMode = (checkedMACList === void 0) // チェックリストが渡ってきていない場合は単一設定画面とする
    useEffect(() => {
        /**
         * MACアドレスから端末情報を取得する。
         * @param {string} mac  
         */
        const getManagementTerminal = async(mac) => {
            try {
                // const response = await API.graphql(graphqlOperation(queries.listTerminalManagementScreenInfosByMac, {MAC: mac, terminalInfoLimit: 1000, terminalInfoOrder: 8700}))
                const MAX_ONE_QUERY_LIMIT = 100
                setOpen(true)
                // const response = await API.graphql(graphqlOperation(queries.listTerminalManagementScreenInfosByMac, {MAC: mac, terminalInfoLimit: MAX_ONE_QUERY_LIMIT, terminalInfoNextToken: nextToken[terminalInfoPage]}))
                const response = await API.graphql(graphqlOperation(queries.listTerminalManagementScreenInfosByMac, {MAC: mac, terminalInfoLimit: MAX_ONE_QUERY_LIMIT, terminalInfoNextToken: nextToken}))
                const terminalSettingScreenItem = response.data.registeredTerminalByMac.items
                if(terminalSettingScreenItem.length) {
                    const resTerminalInfo = terminalSettingScreenItem[0].TerminalInfo
                    // ページングされている（1クエリの取得容量制限もしくは元レコードがMAX_ONE_QUERY_LIMIT件以上）
                    if(resTerminalInfo.nextToken) {
                        if(MAX_ONE_QUERY_LIMIT > resTerminalInfo.items.length) {
                            const limit = MAX_ONE_QUERY_LIMIT - resTerminalInfo.items.length
                            const nextTerminalInfo = await getNextTerminalInfo(resTerminalInfo.nextToken, mac, limit)
                            setTerminalInfo([...resTerminalInfo.items, ...nextTerminalInfo])

                        } else {
                            setTerminalInfo(resTerminalInfo.items)
                            setNextNextToken(resTerminalInfo.nextToken)
                        }
                    } else {
                        setTerminalInfo(resTerminalInfo.items)
                    }
                    // Configの設定
                    const configObj = JSON.parse(terminalSettingScreenItem[0].Config)
                    setConfig(configObj)
                    setTerminalGroupId(terminalSettingScreenItem[0].GroupId)
                    setScriptAction(terminalSettingScreenItem[0].Action)
                    setScriptFileName(terminalSettingScreenItem[0].ScriptPath)
                }
            } catch (e) {
                console.log(e)
                history.goBack()
            }
            setOpen(false)
        }

        /**
         * 複数MACアドレスから端末情報を取得する。
         * @param {[string]} macList
         */
        const getManagementTerminalList = async() => {
            const response = await Promise.all(checkedMACList.map(async mac => {
                try {
                    const response = await API.graphql(graphqlOperation(queries.listTerminalManagementScreenInfosByMac, {MAC: mac}))
                    const terminalSettingScreenItem = response.data.registeredTerminalByMac.items
                    if(terminalSettingScreenItem.length) {
                        const pkeyList = {
                            MAC: mac,
                            GroupId: terminalSettingScreenItem[0].GroupId
                        }
                        return pkeyList
                    }
                } catch (e) {
                    console.log(e)
                    history.goBack()
                }
            }))
            setPrimarykeyList(response)
        }
        

        if(isSingleTerminalSettingMode) {
            getManagementTerminal(dispMac)
        } else {
            getManagementTerminalList()
        }
    }, [nextToken])

    const getNextTerminalInfo = async(nextTerminalInfoToken, mac, nextLimit) => {
        let token = nextTerminalInfoToken
        let limit = nextLimit
        const retTerminalInfoList = []
        do {
            try {
                const response = await API.graphql(graphqlOperation(queries.listTerminalInfos, {nextToken: token, limit: limit, MAC: mac, sortDirection: "DESC"}))
                const termnalLatestData = response.data.listTerminalInfos.items
                token = response.data.listTerminalInfos.nextToken
                retTerminalInfoList.push(...termnalLatestData)
                limit = limit - retTerminalInfoList.length
                if(limit <= 0) {
                    break
                }
            } catch (e) {
                console.log(e)
                token = null
            }
        } while (token)
        console.log(retTerminalInfoList.length)
        console.log(retTerminalInfoList.slice(-1)[0])
        
        setNextNextToken(token)
        return retTerminalInfoList
    }

    const onClickBackBtn = () => {
        history.goBack()
    }

    const onClickUpdateBtn = async() => {
        if(isSingleTerminalSettingMode) {
            const updateInfo = {
                MAC: dispMac,
                Action: scriptAction,
                ScriptPath: scriptFileName,
                GroupId: terminalGroupId
            }
            try {
                await API.graphql({ query: mutations.updateTerminalAction, variables: {input: updateInfo}})
            }catch (e) {
                console.log(e)
            }
        } else {
            await Promise.all(primaryKeyList.map(async primaryKey => {
                const updateInfo = {
                    MAC: primaryKey.MAC,
                    Action: scriptAction,
                    ScriptPath: scriptFileName,
                    GroupId: primaryKey.GroupId
                }
                try {
                    await API.graphql({ query: mutations.updateTerminalAction, variables: {input: updateInfo}})
                } catch (e) {
                    console.log(e)
                }
            }))
        }
    }

    const onClickDeleteBtn = async() => {
        if(isSingleTerminalSettingMode) {
            const updateInfo = {
                MAC: dispMac,
                GroupId: terminalGroupId,
                Model: null,
                Firmware: null,
                Action: false,
                ScriptPath: null,
                Uptime: null,
                WanIp: null,
                CellularModule: null,
                IMEI: null,
                MSISDN: null,
                NetType: null,
                RSSI: null,
                LTERSRP: null,
                NRRSRP: null,
                MCC: null,
                MNC: null,
                Result: "NONE",
                Time: null,
                LastUpdated: null,
                Config: null
            }
            try {
                await API.graphql({ query: mutations.updateTerminalAction, variables: {input: updateInfo}})
            }catch (e) {
                console.log(e)
            }
        } else {
            await Promise.all(primaryKeyList.map(async primaryKey => {
                const updateInfo = {
                    MAC: primaryKey.MAC,
                    GroupId: primaryKey.GroupId,
                    Model: null,
                    Firmware: null,
                    Action: false,
                    ScriptPath: null,
                    Uptime: null,
                    WanIp: null,
                    CellularModule: null,
                    IMEI: null,
                    MSISDN: null,
                    NetType: null,
                    RSSI: null,
                    LTERSRP: null,
                    NRRSRP: null,
                    MCC: null,
                    MNC: null,
                    Result: "NONE",
                    Time: null,
                    LastUpdated: null,
                    Config: null
                }
                try {
                    await API.graphql({ query: mutations.updateTerminalAction, variables: {input: updateInfo}})
                } catch (e) {
                    console.log(e)
                }
            }))
        }
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <TitleHeader  history={props.history}/>
                <Box className={classes.accordionContainer}>
                    {/* Settings */}
                    <Accordion>
                        <AccordionSummary
                            classes={{ content: classes.customAccordionSummary}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography variant="h5">Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                { isSingleTerminalSettingMode ?
                                    <Grid
                                        item
                                        xs={8} >
                                        <Typography variant='h6' align='left'>MAC: {dispMac}</Typography>
                                    </Grid>
                                    : null                                    
                                }
                                <Grid
                                    item
                                    xs={8} >
                                    <TextField
                                        id="outlined-basic"
                                        label="Action"
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        className={classes.textField}
                                        value={scriptAction}
                                        onChange={(e) => setScriptAction(e.target.value)}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={8} >
                                    <TextField
                                        id="outlined-basic"
                                        label="Script file name"
                                        variant="outlined" 
                                        size='small'
                                        fullWidth
                                        className={classes.textField}
                                        value={scriptFileName}
                                        onChange={(e) => setScriptFileName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Box className={classes.btnContainer}>
                                <Button className={classes.btn} variant="contained" color='primary' onClick={onClickUpdateBtn}>Update the Record</Button>
                                <Button className={classes.btn} variant="contained" color='secondary' onClick={onClickDeleteBtn}>Delete the Record</Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    { isSingleTerminalSettingMode &&
                        <>
                            {/* Config */}
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Typography variant="h5">Config</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="a dense table">
                                            <TableBody>
                                                { config != null && Object.keys(config).map((key) => (
                                                    <TableRow
                                                        key={key}
                                                    >
                                                        <TableCell component="th" scope="row" style={{
                                                            borderRight: "0.5px solid #DEDEDE"
                                                            }}>{key}</TableCell>
                                                        <TableCell align="left">{config[key]}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
        
                            {/* Terminal Info  */}
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Typography variant="h5">Info</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TerminalInfoPagination
                                        setNextToken={setNextToken}
                                        nextNextToken={nextNextToken}
                                        previousTokens={previousTokens} 
                                        setPreviousTokens={setPreviousTokens}
                                        nextToken={nextToken}
                                        />
                                    <TerminalInfoTable terminalInfo={terminalInfo} />
                                    
                                </AccordionDetails>
                            </Accordion>
                        </>     
                    }
                </Box>
            </Box>
            <Button variant="contained" style={{marginTop: '30px'}} onClick={onClickBackBtn}>Back</Button>
            <Backdrop open={open} className={classes.backdrop} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default RouterSettingPage;