import React, {useContext, useState} from "react"
import makeStyles from '@material-ui/core/styles/makeStyles'
import {Box, Typography, Divider, Stack, Avatar, Menu, MenuItem} from '@mui/material'

import { Auth } from 'aws-amplify'

import UserContext from '../context/UserContext'

const useStyles = makeStyles({
    title: {
        marginBlock: '2%'
    },
    userGroupIcon: {
        cursor: 'pointer'
    }
})

const TitleHeader = (props) => {
    const classes = useStyles()
    const userContext = useContext(UserContext)
    const useGroupName = userContext.getCurrentUserGroup()[0]
    const {history} = props

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const signOut = async() => {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    const onClickUserGroupIcon = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const onClickLogoutMenu = async () => {
        await signOut()
        history.push({pathname: '/'})
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Box 
                sx={{
                    justifyContent: 'flex-start', alignItems: 'flex-start'
                }}
                className={classes.title}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Typography variant='h3' align='left'>IDY OTA Device Management System</Typography>
                    <Stack className={classes.userGroupIcon} alignItems="center" spacing={0.5} onClick={onClickUserGroupIcon}>
                        <Avatar />
                        <Typography>{useGroupName}</Typography>        
                    </Stack>
                    <Menu
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={onClickLogoutMenu}>Logout</MenuItem>
                    </Menu>
                </Stack>
            </Box>
            <Divider />
        </>
    )
}

export default TitleHeader