import MaterialTable from "material-table";

const TerminalListTable = (props) => {
    const {terminalList, history} = props
    const columns = [
        {
            title: 'Model',
            field: 'Model'
        },
        {
            title: 'Serial Number',
            field: 'SN'
        },
        {
            title: 'MAC Address',
            field: 'MAC'
        },
        {
            title: 'Firmware version',
            field: 'Firmware'
        },
        {
            title: 'Uptime',
            field: 'Uptime'
        },
        {
            title: 'WAN IP',
            field: 'WanIp'
        },
        {
            title: 'Cellular module',
            field: 'CellularModule'
        },
        {
            title: 'IMEI',
            field: 'IMEI'
        },
        {
            title: 'MSISDN',
            field: 'MSISDN'
        },
        {
            title: 'Network Type',
            field: 'NetType'
        },
        {
            title: 'RSSI',
            field: 'RSSI'
        },
        {
            title: 'LTE RSRP',
            field: 'LTERSRP'
        },
        {
            title: 'NR RSRP',
            field: 'NRRSRP'
        },
        {
            title: 'LTE RSRP',
            field: 'LTERSRP'
        },
        {
            title: 'MCC',
            field: 'MCC'
        },
        {
            title: 'MNC',
            field: 'MNC'
        },
        {
            title: 'Time',
            field: 'Time'
        },
        {
            title: 'Action',
            field: 'Action'
        },
        {
            title: 'Result',
            field: 'Result'
        },
        {
            title: 'Last Updated',
            field: 'LastUpdate'
        }
    ]

    const actions = [
        {
            icon: 'description',
            tooltip: 'Details',
            position: 'row',
            onClick: (e, row) => {
                history.push({pathname: '/setting', mac: row.MAC})
            }
        }
    ]

    return (
        <>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
                />
            <MaterialTable
                title={''}
                options={{
                    selection: true
                }}
                columns={columns}
                data={terminalList}
                actions={actions}
                localization={{
                    header: {actions: 'Info' }
                }}
                
            />
        </>
    )
}

export default TerminalListTable