import { Typography } from '@material-ui/core'
import React from 'react'
import SignInPage from '../page/SignInPage'

class Authenticator extends React.Component {
    state = {
        errorMessage: null
    }
    updateErrorMessage = errorMessage => {
        this.setState({ errorMessage })
    }
    render() {
        return (
            <>
                <SignInPage {...this.props} updateErrorMessage={this.updateErrorMessage} />    
            </>
        )
    }
}
export default Authenticator;